import { createSlice } from '@reduxjs/toolkit';

interface IMessage {
  message: string | null;
}

const initialState: IMessage = {
  message: null
};

const messageSlice = createSlice({
  initialState,
  name: 'message',
  reducers: {
    clearMessage: (state) => {
      return {
        ...state,
        message: null
      };
    },
    setMessage: (state, action) => {
      return {
        ...state,
        message: action.payload
      };
    }
  }
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;
export default reducer;
