import * as React from 'react';

import Checkbox from './componentCheckbox';
import InputComponent from './componentInput';
import Textarea from './componentTextarea';

import './style.scss';

interface Props {
  label: string;
  type?: string;
  name?: string;
  id: string;
  errorMsg?: React.ReactNode;
  inputValue: any;
  handleOnChange: any;
  textArea?: boolean;
  textAreaRow?: number;
  icon?: React.ReactNode;
  disabled?: boolean;
  maxLength?: number;
}

const Input: React.FC<Props> = ({
  label,
  type,
  name,
  id,
  errorMsg,
  inputValue,
  handleOnChange,
  textArea,
  textAreaRow = 8,
  icon,
  disabled = false,
  maxLength
}) => {
  const inputId = typeof name !== 'undefined' ? name : id;
  return (
    <React.Fragment>
      {textArea && (
        <Textarea
          label={label}
          id={id}
          errorMsg={errorMsg}
          inputValue={inputValue}
          inputId={inputId}
          handleOnChange={handleOnChange}
          textAreaRow={textAreaRow}
          disabled={disabled}
          maxLength={maxLength}
        />
      )}

      {type === 'checkbox' && (
        <Checkbox
          label={label}
          type={type}
          id={id}
          inputValue={inputValue}
          inputId={inputId}
          handleOnChange={handleOnChange}
          disabled={disabled}
        />
      )}

      {(type === 'text' || type === 'password' || type === 'email') && (
        <InputComponent
          label={label}
          type={type}
          id={id}
          errorMsg={errorMsg}
          inputValue={inputValue}
          inputId={inputId}
          handleOnChange={handleOnChange}
          icon={icon}
          disabled={disabled}
          maxLength={maxLength}
        />
      )}
    </React.Fragment>
  );
};

export default Input;
