import * as React from 'react';

import { Modal, Button } from '../../../..';

interface Props {
  description: string;
  cancelBtn: () => void;
  confirmBtn: () => void;
}

const DynamicModal: React.FC<Props> = ({
  description,
  cancelBtn,
  confirmBtn
}) => {
  return (
    <Modal closeModal={() => cancelBtn()}>
      <p className="text-center">{description}</p>
      <div className="d-flex align-items-center justify-content-between">
        <Button
          text="Cancel"
          className="primary"
          buttonClick={() => cancelBtn()}
        />
        <Button
          text="Delete"
          className="danger"
          buttonClick={() => {
            confirmBtn();
            cancelBtn();
          }}
        />
      </div>
    </Modal>
  );
};

export default DynamicModal;
