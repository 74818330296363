import * as React from 'react';

import './style.scss';

interface Props {
  id?: string;
  text: string;
  subText?: string;
  type: string;
  className?: string;
}

const Title: React.FC<Props> = ({
  id,
  text,
  subText,
  type,
  className = ''
}) => {
  const CustomTag = type as keyof React.JSX.IntrinsicElements;
  return (
    <CustomTag className={`title-component ${className}`} id={id}>
      <span>{text}</span>
      {subText && <i>{subText}</i>}
    </CustomTag>
  );
};

export default Title;
