import { FlippingPages } from 'flipping-pages';
import * as React from 'react';
import 'flipping-pages/dist/style.css';
import { CaretRightFill, CaretLeftFill } from 'react-bootstrap-icons';

import { Image, Title, Button } from '../../';
import {
  IBook,
  IBookPageCol,
  IBookPageRow
} from '../../organisms/dynamicContent/models';

import './style.scss';

interface Props {
  book: IBook;
}

const Book: React.FC<Props> = ({ book }) => {
  const audio = new Audio(require('../../../sounds/page-flip.mp3').default);
  const [selected, setSelected] = React.useState<number>(0);
  const [toggleContent, setToggleContent] = React.useState<boolean>(true);

  const turnPage = React.useCallback(
    (direction: string) => {
      if (direction === 'back') {
        setSelected((selected: number) => Math.max(selected - 1, 0));
      } else {
        setSelected((selected: number) =>
          Math.min(selected + 1, book.value.length)
        );
      }
    },
    [selected, book]
  );

  React.useEffect(() => {
    if (selected !== 0) {
      audio.pause();
      audio.currentTime = 0;
      audio.play();
    }
  }, [selected]);

  const windowSize = typeof window !== 'undefined' && window.innerWidth < 768;
  React.useEffect(() => {
    if (windowSize) {
      setToggleContent(false);
    }
  }, [windowSize]);

  return (
    book && (
      <React.Fragment>
        {toggleContent ? (
          <div className="book">
            <div
              className={`book-container ${selected === 0 ? 'closed' : 'open'}`}
            >
              <FlippingPages
                direction="right-to-left"
                onSwipeEnd={setSelected}
                selected={selected}
              >
                <div className="page">
                  <div className="row">
                    <div className="col first-col"></div>
                    <div className="col">
                      <Image
                        src={
                          require(`../../../images/book/${book.cover.value}`)
                            .default
                        }
                        alt={book.cover.label}
                      />
                    </div>
                  </div>
                </div>
                {book.value.map((row: IBookPageRow) => (
                  <div key={row.pageId} className="page">
                    <div className="row">
                      {row.page.map((col: IBookPageCol) => (
                        <div key={col.pageNumber} className="col paper-bg">
                          <div className="content">
                            {col.title && (
                              <Title
                                text={col.title}
                                type="h4"
                                className="text-center mb-3 chapter-title"
                              />
                            )}
                            <p
                              className="mb-0"
                              dangerouslySetInnerHTML={{
                                __html: col.paragraph
                              }}
                            ></p>
                            <span className="page-number">
                              {col.pageNumber}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </FlippingPages>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-3">
              {selected > 0 && (
                <Button
                  text={selected === 1 ? 'Close book' : 'Prev page'}
                  className="secondary mx-2 active"
                  buttonClick={() => turnPage('back')}
                  icon={selected === 1 ? null : <CaretLeftFill />}
                />
              )}

              <Button
                text={selected === 0 ? 'Open book' : 'Next page'}
                className="primary mx-2 active"
                buttonClick={() => turnPage('next')}
                disabled={selected === book.value.length}
                icon={selected === 0 ? null : <CaretRightFill />}
              />
            </div>
          </div>
        ) : (
          <div className="mobile-img">
            <Image
              src={require(`../../../images/book/${book.cover.value}`).default}
              alt={book.cover.label}
            />
          </div>
        )}
      </React.Fragment>
    )
  );
};

export default Book;
