import * as React from 'react';

import { Button, UserAvatar } from '../../../..';
import { ApiDynamicContentType } from '../../models';

import '../style.scss';

interface Props {
  dynamic: ApiDynamicContentType | null;
  addRow: () => void;
}

const HeaderSetupDynamicContent: React.FC<Props> = ({ dynamic, addRow }) => {
  const dateString = dynamic?.updatedAt ?? '';
  const dateObject = new Date(dateString).toLocaleString();

  return (
    <React.Fragment>
      {dynamic && (
        <div className="dynamic-header">
          <div className="mb-3 d-flex justify-content-center align-items-center">
            <u className="me-3">Last modified by</u>
            <UserAvatar user={dynamic.user} showName />
          </div>
          <p className="text-center mb-0">
            <u className="me-3">Updated at</u>
            <span>{dateObject}</span>
          </p>
        </div>
      )}
      <div className="add-row d-flex align-items-center justify-content-center">
        <Button
          text="New"
          className="primary active"
          buttonClick={() => addRow()}
        />
      </div>
    </React.Fragment>
  );
};

export default HeaderSetupDynamicContent;
