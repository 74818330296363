export type OptionTypes = {
  label: string | number;
  value: string | number;
};

export enum DynamicContentTypeCategory {
  PAGE = 'page',
  POST = 'post',
  NEWSLETTER = 'newsletter'
}

export type ILinkButton = {
  align: string;
  external: boolean;
  id: number;
  linkTo: string;
  type: string;
  value: string;
};

export type IText = {
  align: string;
  id: number;
  subValue: string;
  tag: string;
  value: string;
};

export type ITextarea = {
  align: string;
  id: number;
  tag: string;
  title: string;
  value: string;
};

export type IYoutubeVideo = {
  id: number;
  value: {
    title: string;
    tag: string;
    align: string;
    textarea: string;
    url: string;
  };
};

export type IImage = {
  id: number;
  value: {
    title: string;
    tag: string;
    align: string;
    textarea: string;
    width: string;
    imageName: string | undefined;
    imagePath: string | undefined;
    image: File | null;
  };
};

export type ICarousel = {
  id: number;
  value: {
    align: string;
    title: string;
    description: string;
    imageName: string | undefined;
    imagePath: string | undefined;
    image: File | null;
  };
};

export type IAccordionItems = {
  questionId: number;
  question: string;
  answer: string;
  active?: boolean;
};

export type IAccordion = {
  id: number;
  category: string;
  value: IAccordionItems[];
};

export type ITableItemsHead = {
  thId: number;
  content: string;
};

export type ITableItemsBodyTr = {
  tdId: number;
  content: string;
};

export type ITableItemsBody = {
  trId: number;
  tr: ITableItemsBodyTr[];
  trOrder: number;
};

export type ITableItems = {
  tableId: number;
  tableHead: ITableItemsHead[];
  tableBody: ITableItemsBody[];
};

export type ITable = {
  id: number;
  value: ITableItems;
};

export type IBookPageCol = {
  pageNumber: number;
  title: string | null;
  paragraph: string;
};

export type IBookPageRow = {
  pageId: number;
  page: IBookPageCol[];
};

export type IBook = {
  id: number;
  cover: {
    label: string;
    value: string;
  };
  value: IBookPageRow[];
};

export type ColData = IText &
  ITextarea &
  IImage &
  IYoutubeVideo &
  IAccordion &
  ITable &
  ICarousel[] &
  IBook &
  ILinkButton;

export type ICol = {
  colActive: boolean | null;
  colData: ColData | null;
  colId: number;
  colOrder: number;
  colType: string;
  colWidth: string | undefined;
};

export type IRow = {
  row: ICol[];
  rowBgColor: boolean | undefined;
  rowOrder: number;
  rowType: string;
  uniqueId: string;
};

export type DynamicContentType = {
  _id?: string;
  user: {
    name: string | undefined;
    image?: string | undefined;
    userId: string | undefined;
  };
  pageName: string | undefined;
  container: IRow[];
  category: DynamicContentTypeCategory;
  active?: boolean;
};

export interface ApiDynamicContentType extends DynamicContentType {
  createdAt: string;
  updatedAt: string;
  __v: string;
}
