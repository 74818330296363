import * as React from 'react';

import './style.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  isMounted: boolean | undefined;
}

function useDelayUnmount(isMounted: boolean | undefined, delayTime: number) {
  const [showDiv, setShowDiv] = React.useState<boolean>(false);
  React.useEffect(() => {
    let timeoutId: any;
    if (isMounted && !showDiv) {
      setShowDiv(true);
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), delayTime); //delay our unmount
    }
    return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  }, [isMounted, delayTime, showDiv]);
  return showDiv;
}

const ShowWithAnimation: React.FC<Props> = ({
  children,
  className,
  isMounted
}) => {
  const showDiv = useDelayUnmount(isMounted, 450);
  const mountedStyle = { animation: 'inAnimation 250ms ease-in' };
  const unmountedStyle = {
    animation: 'outAnimation 270ms ease-out',
    animationFillMode: 'forwards'
  };
  return (
    showDiv && (
      <div
        style={isMounted ? mountedStyle : unmountedStyle}
        className={className}
      >
        {children}
      </div>
    )
  );
};

export default ShowWithAnimation;
