import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IUser } from '../../components/atoms/userAvatar';
import {
  IRegisterState,
  ILoginState,
  IUpdateProfile
} from '../../components/organisms/authentication/models';
import ApiService from '../service';

import { setMessage } from './message';

interface IUserState {
  user: IUser | null;
  token: string;
  isLoggedIn: boolean;
}

let user: any | null;
let token: string | null = '';

if (typeof localStorage !== 'undefined') {
  user = JSON.parse(localStorage.getItem('user') as any);
  token = JSON.parse(localStorage.getItem('token') as string);
}

export const register = createAsyncThunk(
  'auth/register',
  async (params: IRegisterState, thunkAPI) => {
    try {
      const result = await ApiService.postRequest(
        '/api/register',
        JSON.stringify(params)
      );

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
        return { _id: data._id, email: data.email, token: data.token };
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (params: ILoginState, thunkAPI) => {
    try {
      const result = await ApiService.postRequest(
        '/api/login',
        JSON.stringify(params)
      );

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
        return { message: data.message, token: data.token, user: data.user };
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const requestResetPassword = createAsyncThunk(
  'auth/request-reset-password',
  async (params: string, thunkAPI) => {
    try {
      const result = await ApiService.postRequest(
        '/api/request-reset-password',
        params
      );

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
        return { message: data.message, token: data.token, user: data.user };
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/reset-password',
  async (params: string, thunkAPI) => {
    try {
      const result = await ApiService.postRequest(
        '/api/reset-password',
        params
      );

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
        return { message: data.message, token: data.token, user: data.user };
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  thunkAPI.dispatch(setMessage('You have been logged out!'));
});

export const profile = createAsyncThunk(
  'auth/profile',
  async (params: IUpdateProfile, thunkAPI) => {
    try {
      const formData = new FormData();
      if (params._id) formData.append('_id', params._id);
      formData.append('name', params.name);
      formData.append('password', params.password);

      if (params.image) {
        formData.append('image', params.image as File);
      }

      const result = await ApiService.patchRequest(
        '/api/profile',
        formData,
        true
      );

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
        return { message: data.message, user: data.user };
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState: IUserState = {
  isLoggedIn: false,
  token: token || '',
  user: user || null
};

const authSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(register.fulfilled, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(register.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(requestResetPassword.fulfilled, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(requestResetPassword.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action?.payload?.message?.includes('Success')) {
          state.isLoggedIn = true;
          localStorage.setItem('user', JSON.stringify(action.payload.user));
          localStorage.setItem('token', JSON.stringify(action.payload.token));

          state.user = action.payload.user;
          state.token = action.payload.token;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false;
        localStorage.removeItem('user');
        localStorage.removeItem('token');

        state.user = null;
        state.token = '';
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.user = null;
        state.token = '';
      })
      .addCase(profile.fulfilled, (state, action) => {
        if (action?.payload?.message?.includes('Success')) {
          localStorage.setItem('user', JSON.stringify(action.payload.user));
          state.user = action.payload.user;
        }
      })
      .addCase(profile.rejected, (state, action) => {});
  },
  initialState,
  name: 'auth',
  reducers: {}
});

const { reducer } = authSlice;
export default reducer;
