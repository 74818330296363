import * as React from 'react';
import { ArrowDownCircleFill } from 'react-bootstrap-icons';

import { Title, Image } from '../..';
import { scrollToSection, windowSize } from '../../../helpers';
import { ICarousel } from '../../organisms/dynamicContent/models';

interface Props {
  carouselData: ICarousel[];
  isOnTop: boolean;
}

const CarouselBanner: React.FC<Props> = ({ carouselData, isOnTop }) => {
  const isSingleData = carouselData.length === 1;
  const bannerObject = carouselData.map((item) => item)[0].value;

  return (
    isSingleData && (
      <div className={`banner ${isOnTop ? 'is-on-top' : ''}`}>
        <div className={`caption ${bannerObject.align}`}>
          <div>
            {bannerObject.title && (
              <Title
                text={bannerObject.title}
                type="h2"
                className="mb-2 title"
              />
            )}
            {bannerObject.description && <p>{bannerObject.description}</p>}
          </div>
        </div>

        {isOnTop ? (
          windowSize ? (
            <Image
              src={`${process.env.GATSBY_API_URL}/${bannerObject.imagePath}`}
              alt={bannerObject.title}
            />
          ) : (
            <React.Fragment>
              <div
                className="cover"
                style={{
                  backgroundImage: `url(${process.env.GATSBY_API_URL}/${bannerObject.imagePath})`
                }}
              ></div>
              <ArrowDownCircleFill
                size={30}
                className="scroll-down"
                onClick={() => scrollToSection('content-wrapper-2', 75)}
              />
            </React.Fragment>
          )
        ) : (
          <Image
            src={`${process.env.GATSBY_API_URL}/${bannerObject.imagePath}`}
            alt={bannerObject.title}
          />
        )}
      </div>
    )
  );
};

export default CarouselBanner;
