import * as React from 'react';

import './style.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Chip: React.FC<Props> = ({ children, className = 'absolute' }) => {
  return <span className={`chip ${className}`}>{children}</span>;
};

export default Chip;
