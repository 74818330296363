import * as React from 'react';

interface Props {
  label: string;
  id: string;
  errorMsg?: React.ReactNode;
  inputValue: any;
  inputId: string | undefined;
  handleOnChange: any;
  textAreaRow?: number;
  disabled?: boolean;
  maxLength?: number;
}

const Textarea: React.FC<Props> = ({
  label,
  id,
  errorMsg,
  inputValue,
  inputId,
  handleOnChange,
  textAreaRow,
  disabled,
  maxLength
}) => {
  return (
    <div className="form-group">
      <label className="mb-2" htmlFor={id}>
        {label}
      </label>
      <textarea
        rows={textAreaRow}
        id={id}
        className="form-control"
        name={inputId}
        placeholder={label}
        value={inputValue}
        disabled={disabled}
        onChange={handleOnChange}
        maxLength={maxLength}
      ></textarea>
      {errorMsg}
    </div>
  );
};

export default Textarea;
