import * as React from 'react';

import { YoutubeVideo, Input, SelectOption } from '../../../..';
import { IYoutubeVideo } from '../../models';
import InputPublish from '../helperComponents/inputPublish';
import { availableTags, availableTextAligns } from '../helpers';

interface Props {
  colActive: boolean | null;
  colData: IYoutubeVideo;
  colId: number;
  uniqueId: string;
  updateCol: (
    colActive: boolean | null,
    colData: IYoutubeVideo,
    colId: number,
    uniqueId: string
  ) => void;
}

const VideoComponent: React.FC<Props> = ({
  colActive,
  colData,
  colId,
  uniqueId,
  updateCol
}) => {
  const [active, setActive] = React.useState<boolean | null>(colActive);
  const [video, setVideo] = React.useState<IYoutubeVideo>({
    id: colId,
    value: {
      align: colData?.value.align || 'center',
      tag: colData?.value.tag || 'h2',
      textarea: colData?.value.textarea || '',
      title: colData?.value.title || '',
      url: colData?.value.url || ''
    }
  });

  const updateVideo = (key: keyof IYoutubeVideo['value'], newValue: string) => {
    setVideo((prevVideo) => ({
      id: colId,
      value: {
        ...prevVideo.value,
        [key]: newValue
      }
    }));
  };

  React.useEffect(() => {
    updateCol(active, video, colId, uniqueId);
  }, [active, video, colId, uniqueId, updateCol]);

  const videoUrl = video.value.url;

  return (
    <div className="component-item">
      <InputPublish
        id={uniqueId + colId}
        active={active}
        setActive={setActive}
      />
      <div className="row">
        <div className={videoUrl ? 'col-md-6' : 'col-md-12'}>
          <div className="row">
            <div className={videoUrl ? 'col-md-12' : 'col-md-6'}>
              <SelectOption
                label="Tag"
                options={availableTags}
                value={video.value.tag}
                className="mb-3"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  updateVideo('tag', e.target.value)
                }
              />
            </div>
            <div className={videoUrl ? 'col-md-12' : 'col-md-6'}>
              <SelectOption
                label="Title Align"
                options={availableTextAligns}
                value={video.value.align}
                className="mb-3"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  updateVideo('align', e.target.value)
                }
              />
            </div>
          </div>

          <div className="row">
            <div className={videoUrl ? 'col-md-12' : 'col-md-6'}>
              <Input
                label="Add Title"
                id={`title_${colId}`}
                type="text"
                errorMsg={
                  video.value.title &&
                  video.value.title.length <= 3 && (
                    <span className="error">
                      Title length is less than 3 characters
                    </span>
                  )
                }
                inputValue={video.value.title}
                handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateVideo('title', e.target.value)
                }
              />
            </div>

            <div className={videoUrl ? 'col-md-12' : 'col-md-6'}>
              <Input
                label="Add Video Embed Id"
                id={`url_${colId}`}
                type="text"
                errorMsg={
                  videoUrl &&
                  videoUrl.length <= 3 && (
                    <span className="error">
                      Embed Id length is less than 3 characters
                    </span>
                  )
                }
                inputValue={videoUrl}
                handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateVideo('url', e.target.value)
                }
              />
            </div>
          </div>

          <Input
            label="Add Textarea"
            id={`textarea_${colId}`}
            textArea
            errorMsg={
              video.value.textarea &&
              video.value.textarea.length <= 3 && (
                <span className="error">
                  Text length is less than 3 characters
                </span>
              )
            }
            inputValue={video.value.textarea}
            handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateVideo('textarea', e.target.value)
            }
          />
        </div>
        {videoUrl && (
          <div className="col-md-6">
            <YoutubeVideo data={video} dev />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoComponent;
