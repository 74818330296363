import { Link } from 'gatsby';
import * as React from 'react';

import { Spinner } from '../../../components';
import navList from '../nav-list';

import './style.scss';

interface Props {
  setToggleNav: (value: boolean) => void;
  availableMenuList: (string | undefined)[] | undefined;
  position: 'in-nav' | 'in-header';
}

const MainMenu: React.FC<Props> = ({
  setToggleNav,
  availableMenuList,
  position
}) => {
  return availableMenuList ? (
    <ul className={`main-menu ${position}`}>
      {navList
        .filter((item) => availableMenuList?.includes(item.text))
        .map((item) => (
          <li key={item.text} onClick={() => setToggleNav(false)}>
            <Link to={item.link} replace>
              <span>{item.text}</span>
            </Link>
          </li>
        ))}
    </ul>
  ) : (
    <Spinner className={position === 'in-header' ? 'me-3' : ''} />
  );
};

export default MainMenu;
