import * as React from 'react';

interface Props {
  className?: string;
}

const Spinner: React.FC<Props> = ({ className = '' }) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${className}`}
      style={{ height: '42px' }}
    >
      <div className="spinner-border text-warning" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
