import * as React from 'react';
import { useSelector } from 'react-redux';

import { Spinner } from '../..';
import { switchSocialIcons } from '../../../helpers';
import { useSiteMetadata } from '../../../hooks';
import { RootState } from '../../../store';

import './style.scss';

const SocialComponent: React.FC = () => {
  const { title } = useSiteMetadata();
  const settingsState = useSelector(
    (state: RootState) => state.settings.settings
  );
  const socialLinks = settingsState
    ?.find((item) => item.settingsType === 'socialLinks')
    ?.container.filter((item) => item.active);

  return socialLinks ? (
    <div className="social-links">
      <p className="mb-4 text-center h6">Follow {title}</p>
      <ul>
        {socialLinks?.map((item) => (
          <li key={item.title}>
            <a
              href={item.link}
              title={`Follow ${title} on ${item.title}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {switchSocialIcons(item.title, 25)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <Spinner />
  );
};

export default SocialComponent;
