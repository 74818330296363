import * as React from 'react';
import { ArrowDownCircle } from 'react-bootstrap-icons';

import { SubscribeToNewsletter } from '../../..';

import './style.scss';

interface Props {
  isFooterInViewport: boolean;
}

const BannerSubscribeToNewsletter: React.FC<Props> = ({
  isFooterInViewport
}) => {
  const [toggleBanner, setToggleBanner] = React.useState<boolean>(true);

  return (
    <div
      id="subscribe-to-newsletter"
      className={toggleBanner && !isFooterInViewport ? 'active' : ''}
    >
      <ArrowDownCircle
        className="hide-banner"
        onClick={() => setToggleBanner(false)}
        size={30}
      />
      <div className="subscribe-form">
        <SubscribeToNewsletter toggle={setToggleBanner} />
      </div>
    </div>
  );
};

export default BannerSubscribeToNewsletter;
