import * as React from 'react';
import { EnvelopeFill, Star } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';

import { Button, Input, Modal, Spinner, Title } from '../..';
import { validationSchema } from '../../../helpers';
import { useForm } from '../../../hooks';
import { AppDispatch } from '../../../store';
import {
  IReviewContent,
  IReviewDetails,
  createEditReview,
  getAllReviews
} from '../../../store/reducers/reviews';
import { subscribeToNewsletter } from '../../../store/reducers/subscribe';

import StarRating from './starRating';

import './style.scss';

interface Props {
  selectedReviewForEdit?: IReviewContent | null;
  setSelectedReviewForEdit?: React.Dispatch<
    React.SetStateAction<IReviewContent | null>
  >;
}

type IReviewValues = {
  error: string;
  value: string;
};

interface IReviewForm {
  email: IReviewValues;
  name: IReviewValues;
  review: IReviewValues;
}

const CreateEditReview: React.FC<Props> = ({
  selectedReviewForEdit,
  setSelectedReviewForEdit
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loadingRequest, setLoadingRequest] = React.useState<boolean>(false);
  const [toggleModal, setToggleModal] = React.useState<boolean>(false);
  const [approveReview, setApproveReview] = React.useState<boolean>(false);
  const [subscribe, setSubscribe] = React.useState<boolean>(false);
  const [rating, setRating] = React.useState<number>(0);

  const schema: IReviewForm = {
    email: {
      error: '',
      value: ''
    },
    name: {
      error: '',
      value: ''
    },
    review: {
      error: '',
      value: ''
    }
  };

  const handleCloseModal = () => {
    if (setSelectedReviewForEdit) {
      setSelectedReviewForEdit(null);
    }
    setToggleModal(false);
  };

  const onSubmitForm = React.useCallback(
    (state: IReviewDetails): void => {
      setLoadingRequest(true);

      const payload = {
        ...state,
        _id: selectedReviewForEdit?._id,
        approved: approveReview,
        rating
      };

      dispatch(createEditReview(payload))
        .then(() => setLoadingRequest(false))
        .then(() => {
          setToggleModal(false);
          handleCloseModal();
          if (setSelectedReviewForEdit) {
            dispatch(getAllReviews());
          }
        });

      if (subscribe) {
        const subscribePayload = {
          email: state.email,
          name: state.name
        };
        dispatch(subscribeToNewsletter(subscribePayload));
      }
    },
    [approveReview, rating, subscribe]
  );

  const {
    dirty,
    errors,
    handleOnChange,
    handleOnSubmit,
    setValues,
    setErrors,
    values
  } = useForm(schema, validationSchema, onSubmitForm);

  const { name, email, review } = values;

  React.useEffect(() => {
    if (selectedReviewForEdit) {
      setToggleModal(true);
      setValues({
        email: selectedReviewForEdit.email,
        name: selectedReviewForEdit.name,
        review: selectedReviewForEdit.review
      });
      setApproveReview(selectedReviewForEdit.approved);
      setRating(selectedReviewForEdit.rating || 0);
      setErrors({
        email: '',
        name: '',
        review: ''
      });
    }
  }, [selectedReviewForEdit]);

  const buttonDisable =
    errors.email !== '' || errors.name !== '' || errors.review !== '';

  return (
    <div className="container">
      {!selectedReviewForEdit && (
        <div className="d-flex justify-content-center">
          <Button
            text="Write a review"
            buttonClick={() => setToggleModal(true)}
            className="primary active mt-4"
          />
        </div>
      )}
      {toggleModal && (
        <Modal closeModal={() => handleCloseModal()} width="full-width">
          <Title
            text={`${selectedReviewForEdit ? 'Edit' : 'Write'} review`}
            type="h2"
            className="text-center mb-4"
          />

          <p className="text-center h6 mb-4">
            <small>
              Only your name, rating, and review will be shown for the public,
              your email address won't be shown!
            </small>
          </p>

          {selectedReviewForEdit && (
            <div className="d-flex justify-content-center">
              <Input
                label={approveReview ? 'Approve' : 'Un approved'}
                type="checkbox"
                id="approveReview"
                inputValue={approveReview}
                handleOnChange={() => setApproveReview(!approveReview)}
              />
            </div>
          )}

          <StarRating rating={rating} setRating={setRating} />

          <form className="mt-4" autoComplete="off" onSubmit={handleOnSubmit}>
            <Input
              label="Your Name"
              type="text"
              id="name"
              errorMsg={
                errors.name &&
                dirty.name && <span className="error">{errors.name}</span>
              }
              inputValue={name}
              handleOnChange={handleOnChange}
            />

            <Input
              label="Your E-Mail"
              type="email"
              id="email"
              errorMsg={
                errors.email &&
                dirty.email && <span className="error">{errors.email}</span>
              }
              inputValue={email}
              handleOnChange={handleOnChange}
            />

            <Input
              label="Your Review"
              id="review"
              textArea
              errorMsg={
                errors.review &&
                dirty.review && <span className="error">{errors.review}</span>
              }
              inputValue={review}
              handleOnChange={handleOnChange}
            />

            {!selectedReviewForEdit && (
              <React.Fragment>
                <p className="text-center h6">
                  <small>Would you like to subscribe to our newsletter?</small>
                </p>
                <div className="d-flex justify-content-center mb-3">
                  <Input
                    label={subscribe ? 'Yes' : 'No'}
                    type="checkbox"
                    id="subscribe"
                    inputValue={subscribe}
                    handleOnChange={() => setSubscribe(!subscribe)}
                  />
                </div>
              </React.Fragment>
            )}

            <div className="d-flex align-items-center justify-content-center">
              {loadingRequest ? (
                <Spinner />
              ) : (
                <Button
                  text="Send"
                  type="submit"
                  className="secondary"
                  disabled={buttonDisable}
                  icon={<EnvelopeFill />}
                />
              )}
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default CreateEditReview;
