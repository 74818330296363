import { navigate } from 'gatsby';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Title, Card, Spinner, Logo } from '../..';
import { RootState } from '../../../store';
import { API_MESSAGE_CLEAR_INTERVAL } from '../../atoms/message';

import './style.scss';

const MessageBody: React.FC = () => {
  const apiMessage = useSelector((state: RootState) => state.message);

  const [message, setMessage] = React.useState<string | null>(null);
  const [countdown, setCountdown] = React.useState<number>(6);

  React.useEffect(() => {
    setMessage(apiMessage.message);
    setTimeout(() => {
      navigate('/');
    }, API_MESSAGE_CLEAR_INTERVAL);
  }, [apiMessage.message]);

  React.useEffect(() => {
    if (message && countdown > 0) {
      const timerId = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [message, countdown]);

  return (
    <div className="un-and-subscribe">
      {message ? (
        <Card>
          <div className="text-center">
            <Logo size={60} />
            <Title type="h5" className="mb-3 mt-3" text={message} />
            <p className="mb-0">
              You will be redirected to the Home page in {countdown} seconds
            </p>
          </div>
        </Card>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default MessageBody;
