import * as React from 'react';

import { Title } from '../..';

interface Props {
  title: string;
}

const PageTitle: React.FC<Props> = ({ title }) => (
  <Title type="h4" text={title} className="text-center mb-4" />
);

export default PageTitle;
