import * as React from 'react';

export enum Theme {
  DARK = 'dark',
  LIGHT = 'light'
}

interface Props {
  children: React.ReactNode;
}

export const ThemeContext = React.createContext<{
  handleThemeChange: (theme: Theme) => void;
  theme: Theme;
}>({
  handleThemeChange: () => {},
  theme: Theme.LIGHT
});

export const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [theme, setTheme] = React.useState<Theme>(() => {
    if (typeof localStorage !== 'undefined') {
      const storedTheme =
        JSON.parse(localStorage.getItem('theme') as string) || Theme.LIGHT;
      if (storedTheme === Theme.DARK) {
        // Set the initial class only if document is defined (client-side).
        if (typeof document !== 'undefined') {
          document.body.classList.add(Theme.DARK);
        }
      } else {
        // Set the initial class only if document is defined (client-side).
        if (typeof document !== 'undefined') {
          document.body.classList.add(Theme.LIGHT);
        }
      }
      return storedTheme || Theme.LIGHT; // Set a default theme if not found in localStorage.
    } else {
      // Set a default class only if document is defined (client-side).
      if (typeof document !== 'undefined') {
        document.body.classList.add(Theme.LIGHT);
      }
      return Theme.LIGHT; // Set a default theme if localStorage is not available.
    }
  });

  const handleThemeChange = React.useCallback(
    (theme: Theme) => {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('theme', JSON.stringify(theme));
      }
      // Toggle classes only if document is defined (client-side).
      if (typeof document !== 'undefined') {
        document.body.classList.toggle(Theme.DARK, theme === Theme.DARK);
        document.body.classList.toggle(Theme.LIGHT, theme === Theme.LIGHT);
      }
      setTheme(theme);
    },
    [theme]
  );

  React.useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      setTheme(
        JSON.parse(localStorage.getItem('theme') as string) || Theme.LIGHT
      );
    }
  }, [theme]);

  const contextValue = React.useMemo(() => {
    return { handleThemeChange, theme };
  }, [handleThemeChange, theme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};
