import * as React from 'react';

import { useSiteMetadata } from '../../../hooks';

interface Props {
  title: string;
  description?: string;
  pathname?: string;
  children?: React.ReactNode;
}

const Seo: React.FC<Props> = ({ title, description, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
    email
  } = useSiteMetadata();

  const seo = {
    description: description ?? defaultDescription,
    email,
    image: `${siteUrl}${image}`,
    title: title || defaultTitle,
    twitterUsername,
    url: `${siteUrl}${pathname ?? ''}`
  };

  const headTitle = `${defaultTitle} ${seo.title}`;
  const publisher = {
    name: 'Austin Macauley Publishers Ltd',
    url: 'https://www.austinmacauley.com/'
  };
  const bookTitle = 'Legira - The Secrets Unfold';

  return (
    <>
      <title>{headTitle}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={headTitle} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Person",
            "url": "${seo.url}",
            "name": "${defaultTitle}",
            "email": "${email}",
            "jobTitle": "Novel Writer",
            "description": "${defaultTitle} is a renowned novelist, known for his captivating storytelling and rich character development.",
            "image": "http://www.example.com/images/eetanner.jpg",
            "gender": "Female",
            "birthDate": "1983-01-15",
            "sameAs": [
              "https://www.facebook.com/eetanner",
              "https://twitter.com/eetanner",
              "https://www.instagram.com/eetanner"
            ],
            "worksFor": {
              "@type": "Organization",
              "name": "${publisher.name}",
              "url": "${publisher.url}"
            }
          }
        `}
      </script>

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Book",
            "name": "${bookTitle}",
            "author": {
              "@type": "Person",
              "name": "${defaultTitle}"
            },
            "image": "http://www.example.com/images/book-cover.jpg",
            "description": "${bookTitle} is a thrilling novel that takes readers on a journey through unexplored lands and unexpected dangers.",
            "datePublished": "2024-02-15",
            "publisher": {
              "@type": "Organization",
              "name": "${publisher.name}",
              "url": "${publisher.url}"
            },
            "isbn": "978-1-035839-13-1",
            "genre": "Fantasy",
            "numberOfPages": 320,
            "bookFormat": "http://schema.org/Paperback",
            "inLanguage": "English"
          }
        `}
      </script>

      {children}
    </>
  );
};

export default Seo;
