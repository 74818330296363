import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  ICol,
  IRow,
  ICarousel,
  DynamicContentType,
  ApiDynamicContentType
} from '../../components/organisms/dynamicContent/models';
import { uppercaseFirstLetter } from '../../helpers';
import ApiService from '../service';

import { setMessage } from './message';

let user: any | null;

export const createEditDynamicContent = createAsyncThunk(
  'dynamicConent/createEditDynamicContent',
  async (params: DynamicContentType, thunkAPI) => {
    try {
      // Create a new FormData instance to store the form data
      const formData = new FormData();

      const imageFiles: File[] = [];

      // Iterate through each item in the params.data array
      const modifiedData = params.container.map((item: IRow) => {
        // Create a modified item with essential properties
        const modifiedItem = {
          row: item.row.map((col: ICol) => {
            if (col.colData && col.colType === 'carousel') {
              col.colData.forEach((carousel: ICarousel) => {
                imageFiles.push(carousel.value.image as File);
              });
            } else if (col.colData && col.colType === 'image') {
              if (col.colData.value.image) {
                imageFiles.push(col.colData.value.image);
              }
            }
            return {
              colActive: col.colActive,
              colData: col.colData,
              colId: col.colId,
              colOrder: col.colOrder,
              colType: col.colType,
              colWidth: col.colWidth
            };
          }),
          rowBgColor: item.rowBgColor,
          rowOrder: item.rowOrder,
          rowType: item.rowType,
          uniqueId: item.uniqueId
        };
        return modifiedItem;
      });

      // Append user-specific data to the FormData instance
      formData.append('_id', JSON.stringify(params._id));
      formData.append('user', JSON.stringify(params.user));
      formData.append('pageName', JSON.stringify(params.pageName));
      formData.append('category', JSON.stringify(params.category));

      // Append the modified data (with nested image paths) to the FormData instance
      formData.append('container', JSON.stringify(modifiedData));

      // Append each image file to the FormData instance
      imageFiles.forEach((imageFile: File) => {
        formData.append('image', imageFile);
      });

      let result;

      if (params._id) {
        result = await ApiService.patchRequest(
          `/api/dynamic/${params._id}`,
          formData,
          true
        );
      } else {
        result = await ApiService.postRequest('/api/dynamic', formData, true);
      }

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
        return { message: data.message };
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDynamicContentByPageName = createAsyncThunk(
  'dynamicConent/getDynamicContentByPageName',
  async (pageName: string, thunkAPI) => {
    try {
      const result = await ApiService.getRequest(`/api/dynamic/${pageName}`);

      if (typeof localStorage !== 'undefined') {
        user = JSON.parse(localStorage.getItem('user') as any);
      }

      if (typeof result.message !== 'undefined') {
        const pageErrorText = uppercaseFirstLetter(pageName);
        const message = result.message.includes('404')
          ? `No content found for ${pageErrorText} page`
          : result.message;
        if (user) thunkAPI.dispatch(setMessage(message));
      } else {
        return { dynamic: result };
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteDynamicContentById = createAsyncThunk(
  'dynamicConent/deleteDynamicContentById',
  async (id: string | undefined, thunkAPI) => {
    try {
      const result = await ApiService.deleteRequest('/api/dynamic/', {
        contentId: id
      });

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllContent = createAsyncThunk(
  'dynamicConent/getAllContent',
  async (_, thunkAPI) => {
    try {
      if (typeof localStorage !== 'undefined') {
        user = JSON.parse(localStorage.getItem('user') as any);
      }

      let result;

      if (user) {
        result = await ApiService.getRequest('/api/dynamic/', {
          user: user._id
        });
      } else {
        result = await ApiService.getRequest('/api/dynamic/');
      }

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else {
        return { allContent: result };
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface RootState {
  allContent: ApiDynamicContentType[] | null;
  dynamic: ApiDynamicContentType | null;
}

const initialState: RootState = {
  allContent: null,
  dynamic: null
};

const dynamicContentSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(createEditDynamicContent.fulfilled, (state, action) => {})
      .addCase(createEditDynamicContent.rejected, (state, action) => {})
      .addCase(getDynamicContentByPageName.fulfilled, (state, action) => {
        state.dynamic = null;
        state.dynamic = action?.payload?.dynamic;
      })
      .addCase(getDynamicContentByPageName.rejected, (state, action) => {})
      .addCase(getAllContent.fulfilled, (state, action) => {
        state.allContent = null;
        state.allContent = action?.payload?.allContent;
      })
      .addCase(getAllContent.rejected, (state, action) => {});
  },
  initialState,
  name: 'dynamicContent',
  reducers: {}
});

const { reducer } = dynamicContentSlice;
export default reducer;
