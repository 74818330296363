import * as React from 'react';

import { ColData, DynamicContentTypeCategory } from '../../models';

import AccordionComponent from './dynamicAccordion';
import BookComponent from './dynamicBook';
import CarouselComponent from './dynamicCarousel';
import ImageComponent from './dynamicImage';
import LinkButtonComponent from './dynamicLinkButton';
import TableComponent from './dynamicTable';
import TextComponent from './dynamicText';
import TextareaComponent from './dynamicTextarea';
import VideoComponent from './dynamicVideo';

interface Props {
  category: DynamicContentTypeCategory;
  colActive: boolean | null;
  colData: ColData;
  colId: number;
  colType: string;
  uniqueId: string;
  updateCol: any;
}

const RenderComponents: React.FC<Props> = (props) => {
  const { category, colActive, colData, colId, colType, uniqueId, updateCol } =
    props;

  const components: { [key: string]: React.JSX.Element } = {
    accordion: (
      <AccordionComponent
        colActive={colActive}
        colData={colData}
        colId={colId}
        uniqueId={uniqueId}
        updateCol={updateCol}
      />
    ),
    book: (
      <BookComponent
        colActive={colActive}
        colData={colData}
        colId={colId}
        uniqueId={uniqueId}
        updateCol={updateCol}
      />
    ),
    button: (
      <LinkButtonComponent
        colActive={colActive}
        colData={colData}
        colId={colId}
        uniqueId={uniqueId}
        updateCol={updateCol}
        category={category}
      />
    ),
    carousel: (
      <CarouselComponent
        colActive={colActive}
        colData={colData}
        colId={colId}
        uniqueId={uniqueId}
        updateCol={updateCol}
      />
    ),
    image: (
      <ImageComponent
        colActive={colActive}
        colData={colData}
        colId={colId}
        uniqueId={uniqueId}
        updateCol={updateCol}
        category={category}
      />
    ),
    table: (
      <TableComponent
        colActive={colActive}
        colData={colData}
        colId={colId}
        uniqueId={uniqueId}
        updateCol={updateCol}
      />
    ),
    text: (
      <TextComponent
        colActive={colActive}
        colData={colData}
        colId={colId}
        uniqueId={uniqueId}
        updateCol={updateCol}
      />
    ),
    textarea: (
      <TextareaComponent
        colActive={colActive}
        colData={colData}
        colId={colId}
        uniqueId={uniqueId}
        updateCol={updateCol}
        category={category}
      />
    ),
    video: (
      <VideoComponent
        colActive={colActive}
        colData={colData}
        colId={colId}
        uniqueId={uniqueId}
        updateCol={updateCol}
      />
    )
  };

  return components[colType] || null;
};

export default RenderComponents;
