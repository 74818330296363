import * as React from 'react';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';

interface Props {
  label: string;
  type?: string;
  id: string;
  errorMsg?: React.ReactNode;
  inputValue: any;
  inputId: string | undefined;
  handleOnChange: any;
  icon?: React.ReactNode;
  disabled?: boolean;
  maxLength?: number;
}

const InputComponent: React.FC<Props> = ({
  label,
  type,
  id,
  errorMsg,
  inputValue,
  inputId,
  handleOnChange,
  icon,
  disabled,
  maxLength
}) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleTypeChange = React.useCallback(() => {
    if (type === 'password') {
      return showPassword ? 'text' : 'password';
    }
    return type;
  }, [showPassword, type]);

  return (
    <div className="form-group">
      <label className="mb-2" htmlFor={id}>
        {label}
      </label>
      <div className="form-group-container">
        {icon && <div className="icon input-icon">{icon}</div>}
        {type === 'password' && (
          <div className="icon password-icon">
            {showPassword ? (
              <EyeSlashFill
                onClick={() => setShowPassword(!showPassword)}
                size={20}
              />
            ) : (
              <EyeFill
                onClick={() => setShowPassword(!showPassword)}
                size={20}
              />
            )}
          </div>
        )}
        <input
          id={id}
          type={handleTypeChange()}
          className={`form-control ${icon ? 'ps-4' : ''}`}
          name={inputId}
          placeholder={label}
          value={inputValue}
          disabled={disabled}
          onChange={handleOnChange}
          maxLength={maxLength}
          autoComplete="off"
        />
      </div>
      {errorMsg}
    </div>
  );
};

export default InputComponent;
