import * as React from 'react';
import {
  ArrowRightCircleFill,
  ArrowDownCircleFill
} from 'react-bootstrap-icons';

import { Title, Chip, ShowWithAnimation } from '../..';
import {
  IAccordion,
  IAccordionItems
} from '../../organisms/dynamicContent/models';

import './style.scss';

interface Props {
  accordion: IAccordion;
}

const Accordion: React.FC<Props> = ({ accordion }) => {
  const [accordionData, setAccordionData] = React.useState<
    Array<IAccordionItems>
  >([]);

  const toggleAccordion = React.useCallback(
    (questionId: number) => {
      const result = accordionData.map((item: IAccordionItems) => {
        return item.questionId === questionId
          ? { ...item, active: !item.active }
          : item;
      });
      setAccordionData(result);
    },
    [accordionData]
  );

  React.useEffect(() => {
    const data = accordion.value.map((item: IAccordionItems, index: number) => {
      return { ...item, active: index === 0 };
    });
    setAccordionData(data);
  }, [accordion]);

  return (
    <div className="accordion-container">
      {accordion.category && (
        <Title type="h2" text={accordion.category} className="title mb-3" />
      )}
      {accordionData?.map((item: IAccordionItems) => (
        <React.Fragment key={item.questionId}>
          {item.question && item.answer && (
            <div className="item-accordion bg-color-1">
              <Chip>{item.questionId}</Chip>
              <h3
                className={`item-title ${item.active ? 'active' : ''}`}
                onClick={() => toggleAccordion(item.questionId)}
              >
                {item.active ? (
                  <ArrowDownCircleFill size={20} />
                ) : (
                  <ArrowRightCircleFill size={20} />
                )}
                <span>{item.question}</span>
              </h3>
              <ShowWithAnimation isMounted={item.active}>
                <p
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                  className="item-content"
                ></p>
              </ShowWithAnimation>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Accordion;
