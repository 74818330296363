import * as React from 'react';
import { ArrowDownCircleFill } from 'react-bootstrap-icons';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './style.scss';

import { Title, Image } from '../..';
import { ICarousel } from '../../organisms/dynamicContent/models';

import CarouselBanner from './banner';

interface Props {
  carouselData: ICarousel[];
  isOnTop: boolean;
}

const Carousel: React.FC<Props> = ({ carouselData, isOnTop }) => {
  return (
    <React.Fragment>
      <CarouselBanner carouselData={carouselData} isOnTop={isOnTop} />
      {carouselData.length > 1 && (
        <div className="carousel">
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, A11y, Autoplay, EffectFade]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            loop={true}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false
            }}
            effect={'fade'}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {carouselData.map((data: ICarousel) => (
              <SwiperSlide
                key={data.value.imagePath}
                className={
                  data.value.title || data.value.description ? 'overlay' : ''
                }
              >
                <div className={`caption ${data.value.align}`}>
                  <div>
                    {data.value.title && (
                      <Title
                        text={data.value.title}
                        type="h2"
                        className="mb-2 title"
                      />
                    )}
                    {data.value.description && <p>{data.value.description}</p>}
                  </div>
                </div>
                <Image
                  src={`${process.env.GATSBY_API_URL}/${data.value.imagePath}`}
                  alt={data.value.title}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </React.Fragment>
  );
};

export default Carousel;
