import { navigate } from 'gatsby';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../store';

type Props = {
  path?: string;
};

const useAuthRedirect: React.FC<Props> = ({ path = '/' }) => {
  const authState = useSelector((state: RootState) => state.auth);

  React.useEffect(() => {
    if (!authState.user) {
      navigate(path);
    }
  }, [authState.user]);

  return null; // Return null or replace it with a JSX element if needed
};

export default useAuthRedirect;
