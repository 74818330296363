import * as React from 'react';

import './style.scss';
import Title from '../../atoms/title';

interface Props {
  rating: number | undefined;
  setRating?: React.Dispatch<React.SetStateAction<number>>;
}

const StarRating: React.FC<Props> = ({ rating, setRating }) => {
  return (
    <div className="star-rating">
      {setRating && (
        <Title
          type="h5"
          className="mb-3 text-center"
          text={`Your rating is ${rating ?? 0} out of 5`}
        />
      )}
      {[...Array.from({ length: 5 }, (_, i) => i + 1)].map((star, index) => {
        const currentRating = index + 1;

        return (
          <label key={index}>
            <input
              className="rating-input"
              key={star}
              type="radio"
              name="rating"
              value={currentRating}
              onChange={() => (setRating ? setRating(currentRating) : null)}
            />
            <span
              className={`star ${currentRating <= (rating ?? 0) ? 'active' : 'in-active'}`}
              onMouseEnter={() => (setRating ? setRating(currentRating) : null)}
              style={{ cursor: setRating ? 'pointer' : 'default' }}
            >
              &#9733;
            </span>
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
