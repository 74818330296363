import * as React from 'react';

import { isInViewport, scrollToSection } from '../../../../../helpers';
import { IRow } from '../../models';

import '../style.scss';

interface Props {
  dynamicContent: IRow[];
}

type SideNav = {
  active: boolean;
  href: string;
  title: string;
};

const SideNavDynamicContent: React.FC<Props> = ({ dynamicContent }) => {
  const [sideNav, setSideNav] = React.useState<SideNav[] | null>();

  const handleScroll = React.useCallback((): void => {
    const sideNav = dynamicContent.flatMap((container, containerIndex) =>
      container.row.map((row) => {
        const title = row.colType;
        const href = `${row.colType}-${containerIndex}`;
        const active = isInViewport(document.getElementById(href));

        return { active, href, title };
      })
    );
    setSideNav(sideNav);
  }, [isInViewport]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  React.useEffect(() => {
    const sideNav = dynamicContent.flatMap((container, containerIndex) =>
      container.row.map((row, index) => {
        const title = row.colType;
        const href = `${row.colType}-${containerIndex}`;
        const active = index === 0 && containerIndex === 0;

        return { active, href, title };
      })
    );
    setSideNav(sideNav);
  }, []);

  return (
    <div id="dynamic-side-nav">
      <ul>
        {sideNav?.map((item) => (
          <li
            key={item.href}
            onClick={() => scrollToSection(item.href, 160)}
            className={item.active ? 'active' : ''}
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideNavDynamicContent;
