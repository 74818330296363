import * as React from 'react';

import { Input, SelectOption } from '../../../..';
import { DynamicContentTypeCategory, ITextarea } from '../../models';
import InputPublish from '../helperComponents/inputPublish';
import { availableTags, availableTextAligns } from '../helpers';

interface Props {
  category: DynamicContentTypeCategory;
  colActive: boolean | null;
  colData: ITextarea;
  colId: number;
  uniqueId: string;
  updateCol: (
    colActive: boolean | null,
    colData: ITextarea,
    colId: number,
    uniqueId: string
  ) => void;
}

const TextareaComponent: React.FC<Props> = ({
  category,
  colActive,
  colData,
  colId,
  uniqueId,
  updateCol
}) => {
  const [active, setActive] = React.useState<boolean | null>(colActive);
  const [textarea, setTextarea] = React.useState<ITextarea>({
    align: colData?.align || 'center',
    id: colId,
    tag: colData?.tag || 'h2',
    title: colData?.title || '',
    value: colData?.value || ''
  });

  const updateTextarea = (key: keyof ITextarea, value: string) => {
    setTextarea((prevTextarea) => ({
      ...prevTextarea,
      [key]: value
    }));
  };

  React.useEffect(() => {
    updateCol(active, textarea, colId, uniqueId);
  }, [active, textarea, colId, uniqueId, updateCol]);

  const isNotNewsletter = category !== 'newsletter';

  return (
    <div className="component-item">
      {isNotNewsletter && (
        <InputPublish
          id={uniqueId + colId}
          active={active}
          setActive={setActive}
        />
      )}
      {isNotNewsletter && (
        <div className="row">
          <div className="col-sm-6">
            <SelectOption
              label="Tag"
              options={availableTags}
              value={textarea.tag}
              className="mb-3"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                updateTextarea('tag', e.target.value)
              }
            />
          </div>
          <div className="col-sm-6">
            <SelectOption
              label="Title Align"
              options={availableTextAligns}
              value={textarea.align}
              className="mb-3"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                updateTextarea('align', e.target.value)
              }
            />
          </div>
        </div>
      )}
      <Input
        label="Add Title"
        id={`text_${colId}`}
        type="text"
        errorMsg={
          textarea.title &&
          textarea.title.length <= 3 && (
            <span className="error">
              Title length is less than 3 characters
            </span>
          )
        }
        inputValue={textarea.title}
        handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateTextarea('title', e.target.value)
        }
      />
      <Input
        label="Add Textarea"
        id={`textarea_${colId}`}
        textArea
        errorMsg={
          textarea.value &&
          textarea.value.length <= 3 && (
            <span className="error">Text length is less than 3 characters</span>
          )
        }
        inputValue={textarea.value}
        handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateTextarea('value', e.target.value)
        }
      />
    </div>
  );
};

export default TextareaComponent;
