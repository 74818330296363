import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IContactDetails } from '../../components/molecules/contactForm';
import ApiService from '../service';

import { setMessage } from './message';

export const sendMessage = createAsyncThunk(
  'contact/sendMessage',
  async (params: IContactDetails, thunkAPI) => {
    try {
      const result = await ApiService.postRequest(
        '/api/sendMessage',
        JSON.stringify(params)
      );

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.data.message));
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState: any = {};

const contactSlice = createSlice({
  initialState,
  name: 'contact',
  reducers: {}
});

const { reducer } = contactSlice;
export default reducer;
