import * as React from 'react';
import { EnvelopeFill, PersonFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';

import { Button, Input, Spinner } from '../../..';
import { validationSchema } from '../../../../helpers';
import { useForm } from '../../../../hooks';
import { AppDispatch } from '../../../../store';
import { subscribeToNewsletter } from '../../../../store/reducers/subscribe';

interface Props {
  toggle?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ISubscriberForm {
  email: {
    value: string | null;
    error: string;
  };
  name: {
    value: string | null;
    error: string;
  };
}

export interface ISubscriber {
  email: string;
  name: string;
}

const SubscribeToNewsletter: React.FC<Props> = ({ toggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loadingRequest, setLoadingRequest] = React.useState<boolean>(false);

  const schema: ISubscriberForm = {
    email: {
      error: '',
      value: ''
    },
    name: {
      error: '',
      value: ''
    }
  };

  const onSubmitForm = React.useCallback((state: ISubscriber): void => {
    setLoadingRequest(true);
    if (toggle) toggle(false);
    dispatch(subscribeToNewsletter(state)).then(() => setLoadingRequest(false));
  }, []);

  const { values, errors, dirty, handleOnChange, handleOnSubmit } = useForm(
    schema,
    validationSchema,
    onSubmitForm
  );

  const { email, name } = values;

  const buttonDisable = errors.email !== '' || errors.name !== '';

  return (
    <div id="sign-up-to-newsletter">
      <p className="mb-4 text-center h6">Subscribe to our newsletter</p>
      <form onSubmit={handleOnSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <Input
              label="Your Name"
              type="text"
              id="name"
              icon={<PersonFill />}
              errorMsg={
                errors.name &&
                dirty.name && <span className="error">{errors.name}</span>
              }
              inputValue={name}
              handleOnChange={handleOnChange}
            />
          </div>
          <div className="col-lg-6">
            <Input
              label="Your E-Mail"
              type="email"
              id="email"
              icon={<EnvelopeFill />}
              errorMsg={
                errors.email &&
                dirty.email && <span className="error">{errors.email}</span>
              }
              inputValue={email}
              handleOnChange={handleOnChange}
            />
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          {loadingRequest ? (
            <Spinner />
          ) : (
            <Button
              text="Subscribe"
              className="secondary"
              type="submit"
              disabled={buttonDisable}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default SubscribeToNewsletter;
