import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, Chip, CreateEditReview, Modal, UserAvatar } from '../..';
import { renderDate } from '../../../helpers';
import { AppDispatch, RootState } from '../../../store';
import {
  IReviewContent,
  deleteReview,
  getAllReviews
} from '../../../store/reducers/reviews';
import Title from '../../atoms/title';

import StarRating from './starRating';

interface ModalValues {
  modalDeleteReview: IReviewContent | null;
}

const ListReviews: React.FC = () => {
  const apiDispatch = useDispatch<AppDispatch>();
  const reviews = useSelector((state: RootState) => state.reviews.reviews);
  const authState = useSelector((state: RootState) => state.auth);

  const [selectedReviewForEdit, setSelectedReviewForEdit] =
    React.useState<IReviewContent | null>(null);

  const [toggleModal, setToggleModal] = React.useState<ModalValues>({
    modalDeleteReview: null
  });

  const handleDeleteReview = (reviewId: string | undefined) => {
    apiDispatch(deleteReview(reviewId)).then(() => {
      setToggleModal({
        ...toggleModal,
        modalDeleteReview: null
      });
      apiDispatch(getAllReviews());
    });
  };

  return (
    <div className="container">
      {reviews && reviews.length > 0 ? (
        <div className="row">
          {reviews?.map((review, index) => (
            <div className="col-md-6 col-lg-4" key={review._id}>
              <Card
                className={`review ${
                  reviews.length - 1 === index ? '' : 'mb-3'
                }`}
              >
                {authState?.user && (
                  <Chip className={review.approved ? '' : 'danger'}>
                    {review.approved ? 'Approved' : 'Pending'}
                  </Chip>
                )}

                <UserAvatar
                  cursor={false}
                  showName
                  user={{ email: review.email, name: review.name }}
                />

                <StarRating rating={review.rating} />

                {authState?.user && (
                  <p className="text-center">{review.email}</p>
                )}

                <p className="mb-0">{review.review}</p>

                {authState?.user && review.createdAt && (
                  <div className="mt-3">
                    Created at {renderDate(review.createdAt)}
                  </div>
                )}

                {authState?.user && (
                  <div className="d-flex justify-content-between mt-4">
                    <Button
                      text="Edit"
                      className="secondary active"
                      buttonClick={() => setSelectedReviewForEdit(review)}
                    />
                    <Button
                      text="Delete"
                      className="danger active"
                      buttonClick={() =>
                        setToggleModal({
                          ...toggleModal,
                          modalDeleteReview: review
                        })
                      }
                    />
                  </div>
                )}
              </Card>
            </div>
          ))}
        </div>
      ) : (
        <Title
          text="Sorry, there are no reviews at this time"
          type="h5"
          className="text-center"
        />
      )}

      {selectedReviewForEdit && (
        <CreateEditReview
          selectedReviewForEdit={selectedReviewForEdit}
          setSelectedReviewForEdit={setSelectedReviewForEdit}
        />
      )}

      {toggleModal.modalDeleteReview && (
        <Modal
          closeModal={() =>
            setToggleModal({
              ...toggleModal,
              modalDeleteReview: null
            })
          }
        >
          <Title
            type="h4"
            text="Are you sure you want to delete this review?"
            className="text-center mb-4"
          />

          <div className="d-flex justify-content-between mt-4">
            <Button
              text="Cancel"
              className="secondary active"
              buttonClick={() =>
                setToggleModal({
                  ...toggleModal,
                  modalDeleteReview: null
                })
              }
            />
            <Button
              text="Delete"
              className="danger active"
              buttonClick={() =>
                handleDeleteReview(toggleModal.modalDeleteReview?._id)
              }
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ListReviews;
