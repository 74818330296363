import { navigate } from 'gatsby';
import * as React from 'react';

import { Button, Spinner } from '../../../..';

import '../style.scss';

interface Props {
  pageName?: string;
  loadingRequest: boolean;
  disableSaveBtn: boolean;
  handleSave: () => void;
}

const FooterSetupDynamicContent: React.FC<Props> = ({
  pageName,
  loadingRequest,
  disableSaveBtn,
  handleSave
}) => {
  return (
    <div className="save-btn d-flex align-items-center justify-content-center">
      {loadingRequest ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Button
            text={pageName ? `Save ${pageName} page` : 'Save'}
            className="secondary active"
            disabled={disableSaveBtn}
            buttonClick={() => handleSave()}
          />
          {pageName && (
            <Button
              text="View"
              className="tertiary active"
              disabled={disableSaveBtn}
              buttonClick={() =>
                navigate(`../${pageName === 'home' ? '/' : pageName}`)
              }
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default FooterSetupDynamicContent;
