const validationSchema = {
  email: {
    required: true,
    validator: {
      error: 'Invalid email address format',
      func: (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    }
  },
  message: {
    required: true,
    validator: {
      error: 'Message is too short',
      func: (value: string) => value.length >= 5
    }
  },
  name: {
    required: true,
    validator: {
      error: 'Please enter a name with minimum 3 characters.',
      func: (value: string) => value.length >= 3
    }
  },
  password: {
    required: true,
    validator: {
      error: 'Password is too short',
      func: (value: string) => value.length >= 6
    }
  },
  passwordRepeat: {
    required: true
    /* validator: {
      error: 'Passwords do not match',
      func: (value: string, values: any) => value === values.password
    } */
  },
  review: {
    required: true,
    validator: {
      error: 'Review is too short',
      func: (value: string) => value.length >= 5
    }
  },
  subject: {
    required: true,
    validator: {
      error: 'Subject is too short',
      func: (value: string) => value.length >= 5
    }
  }
};

export default validationSchema;
