module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"E. E. Tanner","short_name":"E. E. Tanner","start_url":"/","background_color":"#15181f","theme_color":"#15181f","icon":"./src/images/icon.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b90774210c66ca8e107f8068a9bea036"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JTZEH6XC44"],"gtagConfig":{"optimize_id":"GTM-WPTSDDPK","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/dashboard","/settings","/profile","/users","/404","/404.html","/privacy-policy","/authentication","/authentication/password-reset","/subscribe/confirm-email","/subscribe/unsubscribe","/blog"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
