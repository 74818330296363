import * as React from 'react';
import { TrashFill } from 'react-bootstrap-icons';

import { Button, Chip, Input, SelectOption, Title } from '../../../..';
import { IBook, IBookPageRow, IBookPageCol, OptionTypes } from '../../models';
import InputPublish from '../helperComponents/inputPublish';
import { availableBookCovers } from '../helpers';

interface Props {
  colActive: boolean | null;
  colData: IBook;
  colId: number;
  uniqueId: string;
  updateCol: (
    colActive: boolean | null,
    colData: IBook,
    colId: number,
    uniqueId: string
  ) => void;
}

const initialItem: IBook = {
  cover: {
    label: availableBookCovers[0].label as string,
    value: availableBookCovers[0].value as string
  },
  id: 1,
  value: [
    {
      page: [
        {
          pageNumber: 1,
          paragraph: '',
          title: ''
        },
        {
          pageNumber: 2,
          paragraph: '',
          title: ''
        }
      ],
      pageId: 1
    }
  ]
};

const BookComponent: React.FC<Props> = ({
  colActive,
  colData,
  colId,
  uniqueId,
  updateCol
}) => {
  const [active, setActive] = React.useState<boolean | null>(colActive);
  const [book, setBook] = React.useState<IBook>(colData || initialItem);

  const handleAddPages = React.useCallback(() => {
    setBook((prevState: IBook) => {
      if (prevState) {
        const pageId =
          book.value.length > 0
            ? book.value[book.value.length - 1].pageId + 1
            : 1;
        const books = book.value.map((item: IBookPageRow) => item.page);
        const lastPageNumber = books[books.length - 1][1].pageNumber;

        const newItem: IBookPageRow = {
          page: [
            {
              pageNumber: lastPageNumber + 1,
              paragraph: '',
              title: ''
            },
            {
              pageNumber: lastPageNumber + 2,
              paragraph: '',
              title: ''
            }
          ],
          pageId
        };

        return { ...prevState, value: [...prevState.value, newItem] };
      }

      return prevState;
    });
  }, [book.value]);

  const handleRemovePages = (id: number) => {
    setBook((prevState: IBook) => {
      const removedItemIndex = prevState.value.findIndex(
        (row: IBookPageRow) => row.pageId === id
      );

      if (removedItemIndex !== -1) {
        const updatedValue = prevState.value
          .slice(0, removedItemIndex)
          .concat(prevState.value.slice(removedItemIndex + 1))
          .map((item: IBookPageRow, index: number) => {
            // Update page numbers for subsequent items
            if (index >= removedItemIndex) {
              const previousItem = prevState.value[index - 1];
              const lastPageNumber =
                previousItem.page[previousItem.page.length - 1].pageNumber;

              return {
                ...item,
                pageRow: item.page.map((page: IBookPageCol, index: number) => ({
                  ...page,
                  pageNumber: lastPageNumber + (index + 1)
                }))
              };
            }
            return item;
          });

        return {
          ...prevState,
          value: updatedValue
        };
      }

      return prevState;
    });
  };

  const handlePropertyChange = (
    pageId: number,
    pageNumber: number,
    property: keyof IBookPageCol,
    value: string
  ) => {
    setBook((prevState: IBook) => {
      return {
        ...prevState,
        value: prevState.value.map((row: IBookPageRow) => {
          return row.pageId === pageId
            ? {
                ...row,
                page: row.page.map((col: IBookPageCol) => {
                  return col.pageNumber === pageNumber
                    ? { ...col, [property]: value }
                    : col;
                })
              }
            : row;
        })
      };
    });
  };

  React.useEffect(() => {
    updateCol(active, book, colId, uniqueId);
  }, [active, book, colId, uniqueId, updateCol]);

  const coverLabel = availableBookCovers.find(
    (item: OptionTypes) => item.value === book.cover.value
  );

  return (
    <div className="component">
      <InputPublish
        id={uniqueId + colId}
        active={active}
        setActive={setActive}
      />
      <SelectOption
        label="Book Cover"
        options={availableBookCovers}
        value={book.cover.value}
        className="mb-3"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setBook({
            cover: {
              label: coverLabel?.label as string,
              value: e.target.value
            },
            id: book.id,
            value: book.value
          })
        }
      />

      {book.value.map((item: IBookPageRow, index: number) => (
        <div key={item.pageId} className="component-item mb-4 px-4 pb-2 pt-4">
          {index > 0 && (
            <TrashFill
              className="remove-item"
              size={20}
              onClick={() => handleRemovePages(item.pageId)}
            />
          )}
          <div className="row">
            {item.page.map((page: IBookPageCol) => (
              <div
                className="col-md-6 position-relative pt-4"
                key={`${item.pageId}_${page.pageNumber}`}
              >
                <Chip>Page {page.pageNumber}</Chip>
                <div className="pb-2 pt-4">
                  <Input
                    label="Add Title"
                    id={`title_${item.pageId}_${page.pageNumber}`}
                    type="text"
                    maxLength={25}
                    errorMsg={
                      page.title &&
                      page.title.length <= 3 && (
                        <span className="error">
                          Title length is less than 3 characters
                        </span>
                      )
                    }
                    inputValue={page.title}
                    handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handlePropertyChange(
                        item.pageId,
                        page.pageNumber,
                        'title',
                        e.target.value
                      )
                    }
                  />
                  <Title
                    type="h6"
                    className="mb-3"
                    text={`${
                      page.title
                        ? 608 - page.paragraph.length
                        : 657 - page.paragraph.length
                    } characters remaining`}
                  />
                  <Input
                    label="Add Paragraph"
                    id={`paragraph_${item.pageId}_${page.pageNumber}`}
                    textArea
                    maxLength={page.title ? 608 : 657}
                    errorMsg={
                      page.paragraph &&
                      page.paragraph.length <= 3 && (
                        <span className="error">
                          Paragraph length is less than 3 characters
                        </span>
                      )
                    }
                    inputValue={page.paragraph}
                    handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handlePropertyChange(
                        item.pageId,
                        page.pageNumber,
                        'paragraph',
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <Button
        text="Add Pages"
        className="tertiary"
        buttonClick={handleAddPages}
      />
    </div>
  );
};

export default BookComponent;
