import * as React from 'react';
import { XCircleFill } from 'react-bootstrap-icons';

import { Button, Card, Chip, SelectOption, Title } from '../../../..';
import {
  IRow,
  ICol,
  ApiDynamicContentType,
  DynamicContentTypeCategory
} from '../../models';
import RenderComponents from '../components';
import InputBgColor from '../helperComponents/inputBgColor';
import DynamicModal from '../helperComponents/modal';
import {
  availableOrders,
  availableComponents,
  availableWidth,
  hasEmptyImageData
} from '../helpers';
import FooterSetupDynamicContent from '../main/footer';
import HeaderSetupDynamicContent from '../main/header';
import LoadingMessage from '../main/loadingMessage';
import SideNavDynamicContent from '../main/sideNav';

import '../style.scss';

interface Props {
  apiMessage?: string | null;
  category: DynamicContentTypeCategory;
  dynamicState: ApiDynamicContentType | null;
  handleSave: () => void;
  loadingRequest: boolean;
  NEW_DYNAMIC_CONTENT: IRow[];
  pageName?: string;
  stateDispath: React.Dispatch<any>;
}

type Nullable<T> = T | null;

interface ModalValues {
  uniqueId: Nullable<string>;
  colId: Nullable<number>;
  colType: string | null;
}

const DynamicContent: React.FC<Props> = ({
  apiMessage,
  category,
  dynamicState,
  handleSave,
  loadingRequest,
  NEW_DYNAMIC_CONTENT,
  pageName,
  stateDispath
}) => {
  const [toggleModal, setToggleModal] = React.useState<ModalValues>({
    colId: null,
    colType: null,
    uniqueId: null
  });

  const updateCol = React.useCallback(
    (
      colActive: boolean | null,
      colData: any,
      colId: number,
      uniqueId: string
    ) => {
      stateDispath({
        colActive,
        colData,
        colId,
        type: 'UPDATE-COL-DATA',
        uniqueId
      });
    },
    []
  );

  const findRowsWithSameRowIds = React.useCallback(() => {
    const lookup = NEW_DYNAMIC_CONTENT.reduce((a: any, e: any) => {
      a[e.rowOrder] = ++a[e.rowOrder] || 0;
      return a;
    }, {});
    const filterRowOrder = NEW_DYNAMIC_CONTENT.filter(
      (e: IRow) => lookup[e.rowOrder]
    );
    const result = filterRowOrder.map((e: IRow) => e.uniqueId);

    return result;
  }, [NEW_DYNAMIC_CONTENT]);

  const findColsWithSameColIds = React.useCallback(
    (uniqueId: string, colOrder: number) => {
      const lookup = NEW_DYNAMIC_CONTENT.map((row: IRow) => {
        if (row.uniqueId === uniqueId && row.row.length > 1) {
          return row.row.every((col: ICol) => col.colOrder === colOrder);
        }
        return null;
      }).filter((item: any) => item !== null);
      return lookup[0];
    },
    [NEW_DYNAMIC_CONTENT]
  );

  return (
    <div id="dynamic-content-container">
      <HeaderSetupDynamicContent
        dynamic={dynamicState}
        addRow={() => stateDispath({ category, type: 'ADD-ROW' })}
      />
      {toggleModal.uniqueId && toggleModal.colId === null && (
        <DynamicModal
          description="Are you sure you want to remove this row?"
          cancelBtn={() => {
            setToggleModal({
              ...toggleModal,
              uniqueId: null
            });
          }}
          confirmBtn={() => {
            stateDispath({
              type: 'REMOVE-ROW',
              uniqueId: toggleModal.uniqueId
            });
          }}
        />
      )}
      {toggleModal.colId && (
        <DynamicModal
          description={`Are you sure you want to remove this ${toggleModal.colType}?`}
          cancelBtn={() => {
            setToggleModal({
              ...toggleModal,
              colId: null,
              uniqueId: null
            });
          }}
          confirmBtn={() => {
            stateDispath({
              colId: toggleModal.colId,
              type: 'REMOVE-COL',
              uniqueId: toggleModal.uniqueId
            });
          }}
        />
      )}
      {NEW_DYNAMIC_CONTENT.length > 0 ? (
        <div className="row">
          <div className="col-lg-2">
            <SideNavDynamicContent dynamicContent={NEW_DYNAMIC_CONTENT} />
          </div>
          <div className="col-lg-10">
            {NEW_DYNAMIC_CONTENT.map((container, containerIndex) => (
              <Card
                key={container.uniqueId}
                className={`mb-4 setup-dynamic-content ${
                  findRowsWithSameRowIds().includes(container.uniqueId)
                    ? 'dublicated-order'
                    : ''
                }`}
              >
                <Chip>{container.rowOrder}</Chip>
                <XCircleFill
                  size={30}
                  className="remove-row"
                  onClick={() =>
                    setToggleModal({
                      ...toggleModal,
                      uniqueId: container.uniqueId
                    })
                  }
                />

                <div className="add-content">
                  {findRowsWithSameRowIds().includes(container.uniqueId) && (
                    <Title
                      type="h6"
                      text={`One or more rows share order number ${container.rowOrder}.`}
                      className="multi-row-warning"
                    />
                  )}
                  <div className="inner">
                    <SelectOption
                      label={`Content ${category === 'newsletter' ? '' : '(maximum 2)'}`}
                      options={availableComponents(category)}
                      value={container.rowType}
                      disabled={container.row.length === 2}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                        stateDispath({
                          newColType: event.target.value,
                          type: 'UPDATE-ROW-COL-TYPE',
                          uniqueId: container.uniqueId
                        })
                      }
                    />

                    <SelectOption
                      label="Order"
                      options={availableOrders(NEW_DYNAMIC_CONTENT)}
                      value={container.rowOrder}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                        stateDispath({
                          order: event.target.value,
                          type: 'UPDATE-ROW-ORDER',
                          uniqueId: container.uniqueId
                        })
                      }
                    />
                    <Button
                      text="Add"
                      disabled={
                        category !== 'newsletter'
                          ? container.row.length === 2
                          : container.row.length === 1
                      }
                      className="primary"
                      buttonClick={() =>
                        container.row.length === 2
                          ? null
                          : stateDispath({
                              type: 'ADD-COL',
                              uniqueId: container.uniqueId
                            })
                      }
                    />
                  </div>
                </div>

                {container.row.length > 0 ? (
                  <div className="row mt-4">
                    {category !== 'newsletter' && (
                      <InputBgColor
                        id={container.uniqueId}
                        bgColor={container.rowBgColor || false}
                        setBgColor={() =>
                          stateDispath({
                            type: 'ROW-BG-COLOR',
                            uniqueId: container.uniqueId
                          })
                        }
                      />
                    )}
                    {container.row.map((col) => (
                      <div key={col.colId} className="col-md-12">
                        <Title
                          id={`${col.colType}-${containerIndex}`}
                          type="h4"
                          text={col.colType}
                          className="mb-3 text-capitalize"
                        />
                        {container.row.length > 1 && (
                          <div className="row">
                            <div className="col-md-6 mb-3 mt-3">
                              <SelectOption
                                label="Order"
                                className={`${
                                  findColsWithSameColIds(
                                    container.uniqueId,
                                    col.colOrder
                                  )
                                    ? 'danger'
                                    : ''
                                }`}
                                options={availableOrders(container.row)}
                                value={col.colOrder}
                                onChange={(
                                  event: React.ChangeEvent<HTMLSelectElement>
                                ) =>
                                  stateDispath({
                                    colId: col.colId,
                                    order: event.target.value,
                                    type: 'UPDATE-COL-ORDER',
                                    uniqueId: container.uniqueId
                                  })
                                }
                              />
                            </div>
                            <div className="col-md-6 mb-3 mt-3">
                              <SelectOption
                                label="Width"
                                options={availableWidth()}
                                value={col.colWidth}
                                onChange={(
                                  event: React.ChangeEvent<HTMLSelectElement>
                                ) =>
                                  stateDispath({
                                    colId: col.colId,
                                    type: 'UPDATE-COL-WIDTH',
                                    uniqueId: container.uniqueId,
                                    width: event.target.value
                                  })
                                }
                              />
                            </div>
                          </div>
                        )}

                        <RenderComponents
                          colActive={col.colActive}
                          colData={col.colData!}
                          colId={col.colId}
                          colType={col.colType}
                          uniqueId={container.uniqueId}
                          updateCol={updateCol}
                          category={category}
                        />

                        <div className="text-center mt-3">
                          <Button
                            text={`Remove this ${col.colType}`}
                            className="danger"
                            buttonClick={() =>
                              setToggleModal({
                                ...toggleModal,
                                colId: col.colId,
                                colType: col.colType,
                                uniqueId: container.uniqueId
                              })
                            }
                          />
                        </div>

                        {container.row.length === 2 && col.colId === 1 && (
                          <hr />
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <Title
                    type="h5"
                    text="Please select option content"
                    className="text-center mt-4"
                  />
                )}
              </Card>
            ))}
          </div>
        </div>
      ) : (
        <LoadingMessage
          pageName={pageName}
          apiMessage={apiMessage}
          category={category}
        />
      )}

      {(NEW_DYNAMIC_CONTENT.length > 0 || dynamicState?._id) && (
        <FooterSetupDynamicContent
          pageName={pageName}
          loadingRequest={loadingRequest}
          disableSaveBtn={
            hasEmptyImageData(NEW_DYNAMIC_CONTENT) ||
            findRowsWithSameRowIds().length > 0
          }
          handleSave={() => handleSave()}
        />
      )}
    </div>
  );
};

export default DynamicContent;
