import { navigate } from 'gatsby';
import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  ContactForm,
  Spinner,
  Button,
  CreateEditReview,
  ListReviews
} from '../../..';
import { INavlist } from '../../../../layout/navigation/models';
import navList from '../../../../layout/navigation/nav-list';
import { RootState } from '../../../../store';
import { IRow, ApiDynamicContentType, ICol } from '../models';

import DynamicContent from './content';

import './style.scss';

interface Props {
  pageName: string;
}

const RenderDynamicContent: React.FC<Props> = ({ pageName }) => {
  const dynamicState = useSelector((state: RootState) => state.dynamic);
  const authState = useSelector((state: RootState) => state.auth);

  const [data, setData] = React.useState<ApiDynamicContentType | undefined>(
    undefined
  );

  React.useEffect(() => {
    const thisContent = dynamicState.allContent?.find(
      (item) => item.pageName === pageName
    );
    setData(thisContent);
  }, [pageName, dynamicState]);

  const getRows = React.useCallback((): IRow[] | null | undefined => {
    const rows = data?.container
      .map((item: IRow) => {
        const newRow = authState.user
          ? item.row
          : item.row.filter((col: ICol) => col.colActive);
        return { ...item, row: newRow };
      })
      .filter((item: any) => item.row.length > 0);

    const result = rows?.sort(
      (a: any, b: any) => Number(a.rowOrder) - Number(b.rowOrder)
    );

    return data ? result : null;
  }, [data, authState.user]);

  const navigateToPage = React.useCallback(() => {
    const page = navList.find((list: INavlist) => list.text === pageName);
    navigate('/dashboard', { state: { page } });
  }, [pageName]);

  return (
    <div id="dynamic-page-content">
      {getRows() ? (
        <React.Fragment>
          {authState?.user && (
            <div className="dynamic-header">
              {/* <div className="mb-3 d-flex justify-content-center align-items-center">
                <u className="me-3">Last modified by</u>
                <UserAvatar user={dynamicState.dynamic?.user} showName />
              </div> */}
              {authState?.user?.pages?.includes(pageName) && (
                <div className="text-center">
                  <Button
                    text={`Edit ${pageName} page`}
                    buttonClick={() => navigateToPage()}
                  />
                </div>
              )}
            </div>
          )}

          {pageName === 'reviews' && <CreateEditReview />}

          {getRows()?.map((row) => (
            <DynamicContent
              key={row.uniqueId}
              row={row}
              forAdmin={authState.user?.pages?.includes(pageName)}
              category={data?.category}
            />
          ))}

          {pageName === 'contact' && <ContactForm />}

          {pageName === 'reviews' && <ListReviews />}
        </React.Fragment>
      ) : (
        <div className="text-center mt-4">
          <p>Loading... please wait.</p>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default RenderDynamicContent;
