import * as React from 'react';
import { XCircleFill } from 'react-bootstrap-icons';

import Modal from '../../molecules/modal';
import './style.scss';
import Title from '../title';

interface Props {
  src: string;
  alt?: string;
  width?: string;
  className?: string;
  showInPopup?: boolean;
}

const Image: React.FC<Props> = ({
  src,
  alt = '',
  width = '100',
  className = '',
  showInPopup
}) => {
  const [showPopup, setShowPopup] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      {showPopup && (
        <Modal closeModal={() => setShowPopup(false)}>
          <div className="popup">
            <Title text={alt} type="h4" className="text-center mb-3" />
            <img
              src={src}
              className={`img-fluid mx-auto d-block ${className}`}
              alt={alt}
              style={{ width: '75%' }}
            />
          </div>
        </Modal>
      )}
      <img
        src={src}
        className={`img-fluid mx-auto d-block w-${width} ${className}`}
        alt={alt}
        onClick={() => (showInPopup ? setShowPopup(true) : null)}
      />
    </React.Fragment>
  );
};

export default Image;
