import { useLocation } from '@reach/router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../store';
import { detectSubscriber } from '../store/reducers/subscribe';

interface Props {
  pathname: string;
}

export interface IDetectSubscriber {
  pageId: string | undefined;
  pageName: string | undefined;
  email: string | undefined;
}

const useSubscriberVisited: React.FC<Props> = ({ pathname }) => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const dynamicState = useSelector((state: RootState) => state.dynamic);

  const email = new URLSearchParams(location.search).get('email');

  const visitedPage = dynamicState?.allContent?.find(
    (item) => item.pageName === pathname
  );

  React.useEffect(() => {
    if (email && visitedPage) {
      const payload: IDetectSubscriber = {
        email,
        pageId: visitedPage._id,
        pageName: visitedPage.pageName
      };
      dispatch(detectSubscriber(payload));
    }
  }, [email, visitedPage?._id]);

  return null;
};

export default useSubscriberVisited;
