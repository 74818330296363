import * as React from 'react';

interface Props {
  label: string;
  type?: string;
  id: string;
  inputValue: any;
  inputId: string | undefined;
  handleOnChange: any;
  disabled?: boolean;
}

const Checkbox: React.FC<Props> = ({
  label,
  type,
  id,
  inputValue,
  inputId,
  handleOnChange,
  disabled
}) => {
  return (
    <div className="form-check form-switch">
      <input
        type={type}
        name={inputId}
        id={id}
        className="form-check-input"
        value={inputValue}
        disabled={disabled}
        checked={inputValue}
        onChange={handleOnChange}
      />
      <label htmlFor={id} className="form-check-label">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
