import { navigate } from 'gatsby';
import * as React from 'react';

import { Image } from '../../';
import { useSiteMetadata } from '../../../hooks';

import './style.scss';

interface Props {
  size?: number;
}

const Logo: React.FC<Props> = ({ size = 110 }) => {
  const { title } = useSiteMetadata();
  return (
    <div
      className="logo"
      style={{ height: size, width: size }}
      onClick={() => navigate('/')}
    >
      <Image src={require(`../../../images/logo.png`).default} alt={title} />
    </div>
  );
};

export default Logo;
