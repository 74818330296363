import { ITable, ITableItemsBodyTr, ITableItemsHead } from '../../../models';

const addTr = (state: ITable) => {
  const tableBody = state.value.tableBody;
  // Calculate the new trId based on the length of existing tableBody rows
  const trId =
    tableBody.length > 0 ? tableBody[tableBody.length - 1].trId + 1 : 1;

  // Create an array of new ITableItemsBodyTr objects (td elements) for the new row
  const newTr: ITableItemsBodyTr[] = state.value.tableHead.map((_, index) => ({
    // Ensuring a unique tdId within the row by adding index
    content: '', // Initialize content with an empty string
    tdId: index + 1
  }));

  const trOrder =
    tableBody.length > 0 ? tableBody[tableBody.length - 1].trOrder + 1 : 1;

  // Update the state with the new row added to tableBody
  return {
    ...state,
    value: {
      ...state.value,
      tableBody: [...tableBody, { tr: newTr, trId, trOrder }]
    }
  };
};

const removeTr = (state: ITable, action: any) => {
  // Filter out the tableBody row with the specified trId
  const updatedTableBody = state.value.tableBody.filter(
    (row) => row.trId !== action.trId
  );

  // Update the state with the filtered tableBody
  return {
    ...state,
    value: {
      ...state.value,
      tableBody: updatedTableBody
    }
  };
};

const removeTableHeadElement = (state: ITable, action: any) => {
  // Filter out the tableHead element with the specified elementId
  const updatedTableHead = state.value.tableHead.filter(
    (th) => th.thId !== action.thId
  );

  // Filter out the tr element with the specified tdId from tableBody
  const updatedTableBody = state.value.tableBody.map((row) => ({
    tr: row.tr.filter((td) => td.tdId !== action.thId),
    trId: row.trId,
    trOrder: row.trOrder
  }));

  // Remove any empty tr elements from the updated tableBody
  const filteredTableBody = updatedTableBody.filter((row) => row.tr.length > 0);

  // Update the state with the filtered tableHead and tableBody
  return {
    ...state,
    value: {
      ...state.value,
      tableBody: filteredTableBody,
      tableHead: updatedTableHead
    }
  };
};

const addColumn = (state: ITable) => {
  const newThId =
    state.value.tableHead.length > 0
      ? state.value.tableHead[state.value.tableHead.length - 1].thId + 1
      : 1;

  // Create a new tableHead element
  const newTh: ITableItemsHead = {
    content: '', // Set the default content for the new header
    thId: newThId
  };

  // Create an updated tableHead array with the new element
  const updatedTableHead = [...state.value.tableHead, newTh];

  // Create an updated tableBody array with a new ITableItemsBodyTr object in every tr
  const updatedTableBody = state.value.tableBody.map((row) => ({
    tr: [
      ...row.tr,
      {
        content: '', // Initialize content with an empty string
        tdId: newThId // Use the same thId for the new td
      }
    ],
    trId: row.trId,
    trOrder: row.trOrder
  }));

  // Update the state with the updated tableHead and tableBody
  return {
    ...state,
    value: {
      ...state.value,
      tableBody: updatedTableBody,
      tableHead: updatedTableHead
    }
  };
};

const updateHeaderTh = (state: ITable, action: any) => {
  // Clone the existing tableHead array
  const updatedTableHead = state.value.tableHead.map((th) =>
    th.thId === action.thId ? { ...th, content: action.content } : th
  );

  // Update the state with the updated tableHead
  return {
    ...state,
    value: {
      ...state.value,
      tableHead: updatedTableHead
    }
  };
};

const updateBodyTd = (state: ITable, action: any) => {
  // Clone the existing tableBody array
  const updatedTableBody = state.value.tableBody.map((row) => ({
    tr: row.tr.map((td) =>
      row.trId === action.trId && td.tdId === action.tdId
        ? { ...td, content: action.content }
        : td
    ),
    trId: row.trId,
    trOrder: row.trOrder
  }));

  // Update the state with the updated tableBody
  return {
    ...state,
    value: {
      ...state.value,
      tableBody: updatedTableBody
    }
  };
};

const setOrder = (state: ITable, action: any) => {
  return {
    ...state,
    value: {
      ...state.value,
      tableBody: state.value.tableBody.map((row) =>
        row.trId === action.trId ? { ...row, trOrder: action.trOrder } : row
      )
    }
  };
};

const reducer = (state: ITable, action: any) => {
  switch (action.type) {
    case 'ADD-TR':
      return addTr(state);
    case 'REMOVE-TR':
      return removeTr(state, action);
    case 'REMOVE-TABLE-HEAD-ELEMENT':
      return removeTableHeadElement(state, action);
    case 'ADD-COLUMN':
      return addColumn(state);
    case 'UPDATE-HEADER-TH':
      return updateHeaderTh(state, action);
    case 'UPDATE-BODY-TD':
      return updateBodyTd(state, action);
    case 'SET-ORDER':
      return setOrder(state, action);
    default:
      return state;
  }
};

export default reducer;
