import * as React from 'react';

import { Title } from '../..';
import { IYoutubeVideo } from '../../organisms/dynamicContent/models';
import './style.scss';

interface Props {
  data: IYoutubeVideo;
  // hide title and textarea inside dynamic content component
  dev?: boolean;
  className?: string;
}

const YoutubeVideo: React.FC<Props> = ({ data, dev, className = '' }) => {
  return (
    <React.Fragment>
      {data.value.title && !dev && (
        <Title
          type={data.value.tag}
          text={data.value.title}
          className={`text-${data.value.align} mb-3`}
        />
      )}

      {data.value.textarea && !dev && (
        <p dangerouslySetInnerHTML={{ __html: data.value.textarea }}></p>
      )}
      <div className={`video-wrapper ${className}`}>
        <iframe
          src={`https://www.youtube-nocookie.com/embed/${data.value.url}`}
          allowFullScreen
        />
      </div>
    </React.Fragment>
  );
};

export default YoutubeVideo;
