import * as React from 'react';
import {
  Facebook,
  Youtube,
  Instagram,
  Twitter,
  Linkedin,
  Amazon,
  BookHalf
} from 'react-bootstrap-icons';

const switchIcons = (icon: string, size: number): React.ReactNode | null => {
  switch (icon) {
    case 'Instagram':
      return <Instagram size={size} />;
    case 'YouTube':
      return <Youtube size={size} />;
    case 'Twitter':
      return <Twitter size={size} />;
    case 'LinkedIn':
      return <Linkedin size={size} />;
    case 'Amazon':
      return <Amazon size={size} />;
    case 'Goodreads':
      return <BookHalf size={size} />;
    default:
      return <Facebook size={size} />;
  }
};

export default switchIcons;
