import * as React from 'react';
import { XCircleFill } from 'react-bootstrap-icons';
import { createPortal } from 'react-dom';

import Card from '../../atoms/card';

import './style.scss';

interface Props {
  children: React.ReactNode;
  closeModal: () => void;
  width?: 'full-width' | 'half-width';
}

const Modal: React.FC<Props> = ({
  children,
  closeModal,
  width = 'half-width'
}) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Function to set the state to true when the modal is mounted
    setIsVisible(true);

    // Function to set the state to false when the modal is unmounted
    return () => {
      setIsVisible(false);
    };
  }, []);

  // Add a CSS class to the body to handle overflow
  React.useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  return createPortal(
    <div id="modal">
      <div className={`modal-inner ${width}`}>
        <XCircleFill
          className="close-modal"
          onClick={() => closeModal()}
          size={30}
        />
        <Card className="modal-card">{children}</Card>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
