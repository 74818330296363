import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IUser } from '../../components/atoms/userAvatar';
import { INewUser } from '../../components/organisms/users/addUser';
import ApiService from '../service';

import { setMessage } from './message';

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (param: string | undefined, thunkAPI) => {
    try {
      const result = await ApiService.getRequest(`/api/users/${param}`);

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else {
        return { users: result };
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (param: string, thunkAPI) => {
    try {
      const result = await ApiService.deleteRequest('/api/users', {
        userId: param
      });

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addUser = createAsyncThunk(
  'users/addUser',
  async (param: INewUser, thunkAPI) => {
    try {
      const result = await ApiService.postRequest('/api/users', param);
      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (param: IUser, thunkAPI) => {
    try {
      const result = await ApiService.patchRequest('/api/user', param);

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState: any = {
  users: null
};

const usersSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action?.payload?.users;
      })
      .addCase(getUsers.rejected, (state, action) => {});
  },
  initialState,
  name: 'users',
  reducers: {}
});

const { reducer } = usersSlice;
export default reducer;
