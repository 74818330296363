import * as React from 'react';

import { PageTitle } from '../../..';
import { ITabs } from '../models';

import './style.scss';

interface Props {
  data: Array<ITabs>;
}

const TabsVertical: React.FC<Props> = ({ data }) => {
  const [page, setPage] = React.useState<number>(0);
  const getPage = data[page] ? data[page].component : null;

  return (
    <div className="row">
      <div className="col-lg-2">
        <ul className="tabs-vertical">
          {data.map((props, i) => (
            <li
              className={`link ${i === page ? 'active' : ''}`}
              key={i}
              onClick={() => {
                setPage(i), props.callback && props.callback();
              }}
            >
              <PageTitle title={props.title} />
            </li>
          ))}
        </ul>
      </div>
      <div className="col-lg-10">{getPage}</div>
    </div>
  );
};

export default TabsVertical;
