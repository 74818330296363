import * as React from 'react';
import { Person } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

import { Image } from '../../';
import { RootState } from '../../../store';

import './style.scss';

export interface IUser {
  _id?: string | undefined;
  name: string | undefined;
  email?: string | undefined;
  image?: string | undefined;
  isAdmin?: boolean | undefined;
  pages?: string[] | undefined;
}

interface Props {
  cursor?: boolean;
  showName?: boolean;
  size?: number;
  user?: IUser;
}

const UserAvatar: React.FC<Props> = ({
  cursor = true,
  showName = false,
  size = 40,
  user
}) => {
  const authState = useSelector((state: RootState) => state.auth);
  const currentUser = {
    image: user ? user.image : authState?.user?.image,
    name: user ? user.name : authState?.user?.name
  };

  return (
    (user || authState?.user) && (
      <div className="current-user">
        <div
          className="user-inner"
          style={{ cursor: cursor ? 'pointer' : 'default' }}
        >
          {currentUser.image ? (
            <div style={{ height: size, width: size }}>
              <Image
                src={`${process.env.GATSBY_API_URL}/${currentUser.image}`}
              />
            </div>
          ) : (
            <Person size={size} />
          )}
          {showName && <span>{currentUser.name}</span>}
        </div>
      </div>
    )
  );
};

export default UserAvatar;
