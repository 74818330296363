import * as React from 'react';
import { Search, XCircleFill } from 'react-bootstrap-icons';

import { Button, Input, ShowWithAnimation } from '../../../components';

import './style.scss';

enum SearchOnEnum {
  ON_SITE = 'Site',
  ON_WEB = 'Web'
}

type RadiosType = {
  value: SearchOnEnum;
};

type RadioComponentType = {
  searchOn: SearchOnEnum;
  setSearchOn: React.Dispatch<React.SetStateAction<SearchOnEnum>>;
};

const RadioComponent: React.FC<RadioComponentType> = (props) => {
  const radios: RadiosType[] = [
    {
      value: SearchOnEnum.ON_SITE
    },
    {
      value: SearchOnEnum.ON_WEB
    }
  ];

  return (
    <div id="radio" className="ms-3">
      {radios.map((item) => (
        <div key={item.value} className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name={item.value}
            id={item.value}
            checked={props.searchOn === item.value}
            onChange={() => props.setSearchOn(item.value)}
          />
          <label className="form-check-label" htmlFor={item.value}>
            {item.value}
          </label>
        </div>
      ))}
    </div>
  );
};

const SearchForm: React.FC = () => {
  const [query, setQuery] = React.useState<string>('');
  const [show, setShow] = React.useState<boolean>(false);
  const [searchOn, setSearchOn] = React.useState<SearchOnEnum>(
    SearchOnEnum.ON_SITE
  );

  const handleSearch = (query: string, e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      const condition =
        searchOn === SearchOnEnum.ON_SITE
          ? `https://www.google.com/search?as_sitesearch=eetanner.com&q=${query}`
          : `https://www.google.com/search?q=${query}`;

      window.open(condition, '_blank', 'noopener');
    }
  };

  return (
    <React.Fragment>
      <ShowWithAnimation isMounted={show}>
        <form onSubmit={(e) => handleSearch(query, e)} id="search-form">
          <div className="form-inner">
            <Input
              label={`Search on ${searchOn}`}
              type="text"
              id="search"
              inputValue={query}
              handleOnChange={(e: React.FormEvent<HTMLFormElement>) =>
                setQuery(e.currentTarget.value)
              }
              icon={<Search />}
            />
            {/* <RadioComponent searchOn={searchOn} setSearchOn={setSearchOn} /> */}
            <Button
              text="Search"
              type="submit"
              className="secondary mx-3"
              disabled={searchOn === SearchOnEnum.ON_WEB && query === ''}
            />
            <XCircleFill
              size={40}
              onClick={() => setShow(false)}
              className="close-icon"
              title="Close Search"
            />
          </div>
        </form>
      </ShowWithAnimation>
      <Search
        size={20}
        className={`search-icon ${show ? 'active' : ''}`}
        onClick={() => setShow(!show)}
      />
    </React.Fragment>
  );
};

export default SearchForm;
