import * as React from 'react';
import { ArrowBarLeft } from 'react-bootstrap-icons';

import { Logo } from '../../components';

import MainMenu from './mainMenu/mainMenu';

import './style.scss';

interface Props {
  availableMenuList: (string | undefined)[] | undefined;
  setToggleNav: (value: boolean) => void;
  toggleNav: boolean;
  windowInnerWidth: number;
}

const Navigation: React.FC<Props> = ({
  availableMenuList,
  setToggleNav,
  toggleNav,
  windowInnerWidth
}) => {
  const menuClass = toggleNav ? 'visible' : 'hidden';

  return (
    <div id="side-nav" className={menuClass}>
      <div className="nav-box">
        {typeof window !== 'undefined' &&
          window.innerWidth <= windowInnerWidth &&
          toggleNav && (
            <div
              className={`close-icon ${toggleNav ? 'active' : ''}`}
              onClick={() => setToggleNav(false)}
            >
              <ArrowBarLeft size={25} />
            </div>
          )}
        <div className="nav-box-inner">
          <div className="logo-container" onClick={() => setToggleNav(false)}>
            <Logo size={100} />
          </div>
          <MainMenu
            availableMenuList={availableMenuList}
            setToggleNav={setToggleNav}
            position="in-nav"
          />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
