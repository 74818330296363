import * as React from 'react';

import './style.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Card: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <div className={`card ${className}`} style={{ width: '100%' }}>
      <div className="card-body p-4">{children}</div>
    </div>
  );
};

export default Card;
