import * as React from 'react';

import { Input } from '../../../..';

interface Props {
  active: boolean | null;
  id: string;
  label?: string;
  setActive: (value: boolean) => void;
}

const InputPublish: React.FC<Props> = ({ active, id, label, setActive }) => {
  return (
    <div className="mb-3 d-flex">
      <Input
        label={`${
          active ? 'Published' : 'Drafted - only visible for signed in users'
        } ${label || ''}`}
        type="checkbox"
        id={`publish-${id}`}
        inputValue={active}
        handleOnChange={() => setActive(!active)}
      />
    </div>
  );
};

export default InputPublish;
