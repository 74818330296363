import { Link } from 'gatsby';
import * as React from 'react';

import './style.scss';

type TypeLink = {
  internal: boolean;
  link: string;
  title: string;
};

const UsefulLinks: React.FC = () => {
  const links: TypeLink[] = [
    {
      internal: false,
      link: 'https://www.austinmacauley.com/',
      title: 'The Publisher'
    },
    {
      internal: true,
      link: '/contact',
      title: 'Get in touch'
    },
    {
      internal: true,
      link: '/privacy-policy',
      title: 'Privacy Policy'
    }
  ];

  return (
    <div className="useful-links">
      <p className="mb-4 text-center h6">Useful links</p>
      <ul>
        {links.map((link) => (
          <li key={link.title}>
            {link.internal ? (
              <Link to={link.link} title={link.title}>
                {link.title}
              </Link>
            ) : (
              <a
                href={link.link}
                target="_blank"
                title={link.title}
                rel="noopener noreferrer"
              >
                {link.title}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UsefulLinks;
