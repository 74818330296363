import { Buffer } from 'buffer';

import { navigate } from 'gatsby';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Logo, Message, Spinner } from '../components';
import {
  ApiDynamicContentType,
  DynamicContentTypeCategory
} from '../components/organisms/dynamicContent/models';
import { ThemeProvider } from '../context/theme';
import { uppercaseFirstLetter, windowInnerWidth, windowSize } from '../helpers';
import { useSubscriberVisited } from '../hooks';
import { AppDispatch, RootState } from '../store';
import { logout } from '../store/reducers/auth';
import { getAllContent } from '../store/reducers/dynamicContent';
import { getAllReviews, getApprovedReviews } from '../store/reducers/reviews';
import { getSettings } from '../store/reducers/settings';
import { getSubscribers } from '../store/reducers/subscribe';

import Footer from './footer';
import Header from './header';
import Main from './main';
import Navigation from './navigation';

import './layout.scss';

interface Props {
  children: React.ReactNode;
  uri: string;
}

const Layout: React.FC<Props> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const authState = useSelector((state: RootState) => state.auth);
  const dynamicState = useSelector((state: RootState) => state.dynamic);

  const ref = React.createRef<HTMLDivElement>();
  const pathname = props.uri !== '/' ? props.uri.split('/')[1] : 'home';

  const [toggleNav, setToggleNav] = React.useState<boolean>(false);
  const [footerHeight, getFooterHeight] = React.useState<number>(0);
  const [loader, setLoader] = React.useState<boolean>(true);

  const availableMenuList = dynamicState?.allContent
    ?.filter((item) => item.category === DynamicContentTypeCategory.PAGE)
    .map((item: ApiDynamicContentType) => item.pageName);

  const expiredToken = React.useCallback(() => {
    const parseJwt = (token: string) => {
      const base64Payload: any = token ? token.split('.')[1] : [];
      const payload = Buffer.from(base64Payload, 'base64');
      return token ? JSON.parse(payload.toString()) : '';
    };
    const token = JSON.parse(localStorage.getItem('token') as string) || '';
    const jwToken = token || '';
    if (Date.now() >= parseJwt(token).exp * 1000 || token !== jwToken) {
      dispatch(logout()).then(() => navigate('/'));
    }
  }, []);

  React.useEffect(() => {
    expiredToken();
  }, [expiredToken]);

  React.useEffect(() => {
    if (windowSize) {
      setToggleNav(false);
    }
  }, [windowSize]);

  React.useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.innerWidth <= windowInnerWidth
    ) {
      document.body.style.overflow = toggleNav ? 'hidden' : 'auto';
    }
  }, [toggleNav]);

  useSubscriberVisited({ pathname });

  React.useEffect(() => {
    dispatch(getAllContent())
      .then(() => {
        dispatch(getSettings());
      })
      .then(() => setLoader(false));
  }, []);

  React.useEffect(() => {
    if (authState.user) {
      dispatch(getSubscribers());
      dispatch(getAllReviews());
    } else {
      dispatch(getApprovedReviews());
    }
  }, [authState.user]);

  const pageTitle = uppercaseFirstLetter(
    availableMenuList?.find((item) => item === pathname)
  );

  const renderConditions = {
    header: pathname !== 'authentication' && pathname !== 'subscribe',
    nav:
      ((pathname !== 'authentication' && pathname !== 'subscribe') ||
        toggleNav) &&
      typeof window !== 'undefined' &&
      window.innerWidth <= windowInnerWidth,
    overlay:
      typeof window !== 'undefined' &&
      window.innerWidth <= windowInnerWidth &&
      toggleNav
  };

  return (
    <ThemeProvider>
      <div
        ref={ref}
        id={pathname}
        className="wrapper"
        style={{ paddingBottom: `${footerHeight}px` }}
      >
        {loader ? (
          <div className="loader">
            <div>
              <Logo size={100} />
              <p>Just a moment...</p>
              <Spinner />
            </div>
          </div>
        ) : (
          <div className="wrapper-inner">
            <Message />

            {renderConditions.nav && (
              <Navigation
                windowInnerWidth={windowInnerWidth}
                toggleNav={toggleNav}
                setToggleNav={setToggleNav}
                availableMenuList={availableMenuList}
              />
            )}

            {renderConditions.overlay && (
              <div id="overlay" onClick={() => setToggleNav(false)}></div>
            )}

            {renderConditions.header && (
              <Header
                pathname={pathname}
                setToggleNav={setToggleNav}
                availableMenuList={availableMenuList}
                enableSideNav={
                  typeof window !== 'undefined' &&
                  window.innerWidth <= windowInnerWidth
                }
              />
            )}

            <Main pageTitle={pageTitle}>{props.children}</Main>

            <Footer getFooterHeight={getFooterHeight} pathname={pathname} />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Layout;
