import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ApiService from '../service';

import { setMessage } from './message';

export interface IReviewDetails {
  email: string;
  name: string;
  review: string;
}

export interface IReviewContent extends IReviewDetails {
  _id?: string;
  approved: boolean;
  createdAt?: string;
  rating?: number;
}

export const createEditReview = createAsyncThunk(
  'reviews/createEditReview',
  async (params: IReviewContent, thunkAPI) => {
    try {
      let result;

      if (params._id) {
        result = await ApiService.patchRequest(
          `/api/reviews/sendReview/${params._id}`,
          params
        );
      } else {
        result = await ApiService.postRequest(
          '/api/reviews/sendReview',
          JSON.stringify(params)
        );
      }

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.data.message));
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getApprovedReviews = createAsyncThunk(
  'reviews/getApprovedReviews',
  async (_, thunkAPI) => {
    try {
      const result = await ApiService.getRequest('/api/reviews/approved');

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else {
        return { reviews: result };
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllReviews = createAsyncThunk(
  'reviews/getAllReviews',
  async (_, thunkAPI) => {
    try {
      const result = await ApiService.getRequest('/api/reviews');

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else {
        return { reviews: result };
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteReview = createAsyncThunk(
  'reviews/deleteReview',
  async (param: string | undefined, thunkAPI) => {
    try {
      const result = await ApiService.deleteRequest('/api/reviews', {
        reviewId: param
      });

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface IReview {
  reviews: IReviewContent[] | null;
}

const initialState: IReview = {
  reviews: null
};

const reviewSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getApprovedReviews.fulfilled, (state, action) => {
        state.reviews = action?.payload?.reviews;
      })
      .addCase(getApprovedReviews.rejected, (state, action) => {})
      .addCase(getAllReviews.fulfilled, (state, action) => {
        state.reviews = action?.payload?.reviews;
      })
      .addCase(getAllReviews.rejected, (state, action) => {});
  },
  initialState,
  name: 'review',
  reducers: {}
});

const { reducer } = reviewSlice;
export default reducer;
