import * as React from 'react';

import { Input, SelectOption } from '../../../..';
import { IText } from '../../models';
import InputPublish from '../helperComponents/inputPublish';
import { availableTags, availableTextAligns } from '../helpers';

interface Props {
  colActive: boolean | null;
  colData: IText;
  colId: number;
  uniqueId: string;
  updateCol: (
    colActive: boolean | null,
    colData: IText,
    colId: number,
    uniqueId: string
  ) => void;
}

const TextComponent: React.FC<Props> = ({
  colActive,
  colData,
  colId,
  uniqueId,
  updateCol
}) => {
  const [active, setActive] = React.useState<boolean | null>(colActive);
  const [text, setText] = React.useState<IText>({
    align: colData?.align || 'center',
    id: colId,
    subValue: colData?.subValue || '',
    tag: colData?.tag || 'h1',
    value: colData?.value || ''
  });

  const updateText = (key: keyof IText, value: string) => {
    setText((prevTextarea) => ({
      ...prevTextarea,
      [key]: value
    }));
  };

  React.useEffect(() => {
    updateCol(active, text, colId, uniqueId);
  }, [active, text, colId, uniqueId, updateCol]);

  return (
    <div className="component-item">
      <InputPublish
        id={uniqueId + colId}
        active={active}
        setActive={setActive}
      />

      <div className="row">
        <div className="col-sm-6">
          <SelectOption
            label="Tag"
            options={availableTags}
            value={text.tag}
            className="mb-3"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              updateText('tag', e.target.value)
            }
          />
        </div>
        <div className="col-sm-6">
          <SelectOption
            label="Text Align"
            options={availableTextAligns}
            value={text.align}
            className="mb-3"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              updateText('align', e.target.value)
            }
          />
        </div>
      </div>

      <Input
        label="Add Title"
        id={`text_${colId}`}
        type="text"
        errorMsg={
          text.value &&
          text.value.length <= 3 && (
            <span className="error">Text length is less than 3 characters</span>
          )
        }
        inputValue={text.value}
        handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateText('value', e.target.value)
        }
      />

      <Input
        label="Add Sub Title"
        id={`subValue_${colId}`}
        type="text"
        errorMsg={
          text.subValue &&
          text.subValue.length <= 3 && (
            <span className="error">Text length is less than 3 characters</span>
          )
        }
        inputValue={text.subValue}
        handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateText('subValue', e.target.value)
        }
      />
    </div>
  );
};

export default TextComponent;
