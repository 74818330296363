import * as React from 'react';
import { TrashFill } from 'react-bootstrap-icons';

import {
  Input,
  ImageUpload,
  Button,
  Title,
  Image,
  SelectOption
} from '../../../..';
import { ICarousel } from '../../models';
import InputPublish from '../helperComponents/inputPublish';
import { availableTextAligns } from '../helpers';

interface Props {
  colActive: boolean | null;
  colData: ICarousel[];
  colId: number;
  uniqueId: string;
  updateCol: (
    colActive: boolean | null,
    colData: ICarousel[],
    colId: number,
    uniqueId: string
  ) => void;
}

const initialItems: ICarousel[] = [
  {
    id: 1,
    value: {
      align: 'left',
      description: '',
      image: null,
      imageName: '',
      imagePath: '',
      title: ''
    }
  }
];

const CarouselComponent: React.FC<Props> = ({
  colActive,
  colData,
  colId,
  uniqueId,
  updateCol
}) => {
  const [active, setActive] = React.useState<boolean | null>(colActive);
  const [items, setItems] = React.useState<ICarousel[]>(
    colData || initialItems
  );

  const handleAddItem = () => {
    const newItemId = items.length > 0 ? items[items.length - 1].id + 1 : 1;
    const newItem: ICarousel = {
      id: newItemId,
      value: {
        align: 'left',
        description: '',
        image: null,
        imageName: '',
        imagePath: '',
        title: ''
      }
    };
    setItems([...items, newItem]);
  };

  const handlePropertyChange = (
    id: number,
    property: keyof ICarousel['value'],
    value: any
  ) => {
    const updatedItems = items.map((item) =>
      item.id === id
        ? {
            ...item,
            value: {
              ...item.value,
              imageName:
                property === 'image'
                  ? `${value?.name}_${value?.size}`
                  : item.value.imageName,
              imagePath: property === 'image' ? '' : item.value.imagePath,
              [property]: value
            }
          }
        : item
    );
    setItems(updatedItems);
  };

  const handleRemoveItem = (id: number) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };

  React.useEffect(() => {
    updateCol(active, items, colId, uniqueId);
  }, [active, items, colId, uniqueId, updateCol]);

  return (
    <div className="component">
      <InputPublish
        id={uniqueId + colId}
        active={active}
        setActive={setActive}
      />
      {items.map((item, index) => (
        <div key={item.id} className="mb-4 component-item">
          <Title
            type="h4"
            text={`Slide ${index + 1}`}
            className="title mb-3 text-capitalize"
          />
          <TrashFill
            className="remove-item"
            size={20}
            onClick={() => handleRemoveItem(item.id)}
          />
          <div className="row">
            <div className="col-md-6">
              <Input
                label="Add Title"
                id={`title_${item.id}`}
                type="text"
                errorMsg={
                  item.value.title &&
                  item.value.title.length <= 3 && (
                    <span className="error">
                      Title length is less than 3 characters
                    </span>
                  )
                }
                inputValue={item.value.title}
                handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handlePropertyChange(item.id, 'title', e.target.value)
                }
              />
              <Input
                label="Add Description"
                id={`description_${item.id}`}
                textArea
                errorMsg={
                  item.value.description &&
                  item.value.description.length <= 3 && (
                    <span className="error">
                      Description length is less than 3 characters
                    </span>
                  )
                }
                inputValue={item.value.description}
                handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handlePropertyChange(item.id, 'description', e.target.value)
                }
              />

              <SelectOption
                label="Content Align"
                options={availableTextAligns}
                value={item.value.align}
                className="mb-3"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  handlePropertyChange(item.id, 'align', e.target.value)
                }
              />
            </div>
            <div className="col-md-6">
              {item.value.imagePath && (
                <Image
                  width="100"
                  src={`${process.env.GATSBY_API_URL}/${item.value.imagePath}`}
                />
              )}
              <ImageUpload
                value={item.value.image}
                onChange={(file) =>
                  handlePropertyChange(item.id, 'image', file)
                }
              />
            </div>
          </div>
        </div>
      ))}
      <Button
        text="Add Item"
        className="tertiary"
        buttonClick={handleAddItem}
      />
    </div>
  );
};

export default CarouselComponent;
