import * as React from 'react';

import { Spinner } from '../../../..';
import { uppercaseFirstLetter } from '../../../../../helpers';
import { DynamicContentTypeCategory } from '../../models';

interface Props {
  apiMessage?: string | null;
  category: DynamicContentTypeCategory;
  pageName?: string;
}

const LoadingMessage: React.FC<Props> = ({
  apiMessage,
  category,
  pageName
}) => {
  return (
    pageName && (
      <div className="text-center mb-3">
        {apiMessage ? (
          <p>{apiMessage}</p>
        ) : (
          <React.Fragment>
            <p>
              Loading {uppercaseFirstLetter(pageName)} {category} content,
              please wait.
            </p>
            <Spinner />
          </React.Fragment>
        )}
      </div>
    )
  );
};

export default LoadingMessage;
