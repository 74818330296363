import { Link } from 'gatsby';
import * as React from 'react';

import {
  Image,
  Carousel,
  Title,
  YoutubeVideo,
  Accordion,
  Table,
  Book,
  Chip
} from '../../..';
import {
  IRow,
  ICol,
  IImage,
  ITextarea,
  IText,
  IYoutubeVideo,
  IAccordion,
  ITable,
  IBook,
  ILinkButton,
  DynamicContentTypeCategory
} from '../models';

import './style.scss';

interface Props {
  category: DynamicContentTypeCategory | undefined;
  forAdmin?: boolean;
  row: IRow | null | undefined;
}

const DynamicContent: React.FC<Props> = ({ category, forAdmin, row }) => {
  const isCarouselTop = row?.rowOrder === 1 && row?.rowType === 'carousel';
  const containerClassName = `${isCarouselTop ? 'container-fluid main-top' : 'container'}`;
  return (
    <div
      id={`content-wrapper-${row?.rowOrder}`}
      className={`render-dynamic-content ${containerClassName}`}
    >
      <div
        className={`row ${row?.rowBgColor ? 'bg-color-1' : ''}`}
        key={row?.rowOrder}
      >
        {row?.row.map((col: ICol) => (
          <div key={col.colId} className={`column col-lg-${col.colWidth}`}>
            <div className="content">
              {forAdmin && (
                <Chip className={`${col.colActive ? '' : 'danger'}`}>
                  {col.colActive ? 'Public' : 'Draft'}
                </Chip>
              )}
              {Array.isArray(col.colData) && (
                <Carousel carouselData={col.colData} isOnTop={isCarouselTop} />
              )}
              {!Array.isArray(col.colData) && (
                <React.Fragment>
                  {col.colType === 'image' && col.colData && (
                    <div className="row">
                      <div
                        className={`${(col.colData as IImage).value.textarea && category !== 'newsletter' ? 'col-sm-4 col-md-3' : 'col-sm-12'}`}
                      >
                        <Image
                          src={`${process.env.GATSBY_API_URL}/${
                            (col.colData as unknown as IImage).value.imagePath
                          }`}
                          alt={(col.colData as IImage).value.title}
                          width={(col.colData as IImage).value.width}
                        />
                      </div>
                      {(col.colData as IImage).value.textarea && (
                        <div
                          className={`${category !== 'newsletter' ? 'col-sm-8 col-md-9' : 'col-sm-12'}`}
                        >
                          <Title
                            type={(col.colData as unknown as IImage).value.tag}
                            text={
                              (col.colData as unknown as IImage).value.title
                            }
                            className={`text-${
                              (col.colData as unknown as IImage).value.align
                            } mt-3 mb-4`}
                          />
                          <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html: (col.colData as unknown as IImage).value
                                .textarea
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                  )}

                  {col.colType === 'text' && col.colData && (
                    <Title
                      type={(col.colData as unknown as IText).tag}
                      text={(col.colData as unknown as IText).value}
                      subText={(col.colData as unknown as IText).subValue}
                      className={`text-${
                        (col.colData as unknown as IText).align
                      }`}
                    />
                  )}

                  {col.colType === 'textarea' && col.colData && (
                    <React.Fragment>
                      {(col.colData as unknown as ITextarea).title && (
                        <Title
                          type={(col.colData as unknown as ITextarea).tag}
                          text={(col.colData as unknown as ITextarea).title}
                          className={`text-${
                            (col.colData as unknown as ITextarea).align
                          } mb-3`}
                        />
                      )}
                      <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                          __html: (col.colData as unknown as ITextarea).value
                        }}
                      ></p>
                    </React.Fragment>
                  )}

                  {col.colType === 'video' && col.colData && (
                    <YoutubeVideo
                      data={col.colData as unknown as IYoutubeVideo}
                    />
                  )}

                  {col.colType === 'accordion' && col.colData && (
                    <Accordion
                      accordion={col.colData as unknown as IAccordion}
                    />
                  )}

                  {col.colType === 'book' && col.colData && (
                    <Book book={col.colData as unknown as IBook} />
                  )}

                  {col.colType === 'button' && col.colData && (
                    <div
                      className={`text-${(col.colData as unknown as ILinkButton).align}`}
                    >
                      {(col.colData as unknown as ILinkButton).external ||
                      category === DynamicContentTypeCategory.NEWSLETTER ? (
                        <a
                          className={`btn btn-${(col.colData as unknown as ILinkButton).type}`}
                          href={(col.colData as unknown as ILinkButton).linkTo}
                          title={(col.colData as unknown as ILinkButton).value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>
                            {(col.colData as unknown as ILinkButton).value}
                          </span>
                        </a>
                      ) : (
                        <Link
                          className={`btn btn-${(col.colData as unknown as ILinkButton).type}`}
                          to={
                            '/' + (col.colData as unknown as ILinkButton).linkTo
                          }
                          title={(col.colData as unknown as ILinkButton).value}
                        >
                          <span>
                            {(col.colData as unknown as ILinkButton).value}
                          </span>
                        </Link>
                      )}
                    </div>
                  )}

                  {col.colType === 'table' && col.colData && (
                    <Table data={(col.colData as unknown as ITable).value} />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DynamicContent;
