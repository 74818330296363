import * as React from 'react';
import { EnvelopeFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';

import { Card, Input, Button, Spinner } from '../..';
import { validationSchema } from '../../../helpers';
import { useForm } from '../../../hooks';
import { AppDispatch } from '../../../store';
import { sendMessage } from '../../../store/reducers/contact';

type IContactValues = {
  error: string;
  value: string;
};

interface IContactForm {
  email: IContactValues;
  message: IContactValues;
  name: IContactValues;
  subject: IContactValues;
}

export interface IContactDetails {
  email: string;
  message: string;
  name: string;
  subject: string;
}

const ContactForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loadingRequest, setLoadingRequest] = React.useState<boolean>(false);

  const schema: IContactForm = {
    email: {
      error: '',
      value: ''
    },
    message: {
      error: '',
      value: ''
    },
    name: {
      error: '',
      value: ''
    },
    subject: {
      error: '',
      value: ''
    }
  };

  const onSubmitForm = React.useCallback((state: IContactDetails): void => {
    setLoadingRequest(true);
    dispatch(sendMessage(state)).then(() => setLoadingRequest(false));
  }, []);

  const { values, errors, dirty, handleOnChange, handleOnSubmit, disable } =
    useForm(schema, validationSchema, onSubmitForm);

  const { name, email, subject, message } = values;

  return (
    <div className="container">
      <Card>
        <form
          className="needs-validation"
          autoComplete="off"
          onSubmit={handleOnSubmit}
        >
          <Input
            label="Your Name"
            type="text"
            id="name"
            errorMsg={
              errors.name &&
              dirty.name && <span className="error">{errors.name}</span>
            }
            inputValue={name}
            handleOnChange={handleOnChange}
          />

          <Input
            label="Your E-Mail"
            type="email"
            id="email"
            errorMsg={
              errors.email &&
              dirty.email && <span className="error">{errors.email}</span>
            }
            inputValue={email}
            handleOnChange={handleOnChange}
          />

          <Input
            label="Subject"
            type="text"
            id="subject"
            errorMsg={
              errors.subject &&
              dirty.subject && <span className="error">{errors.subject}</span>
            }
            inputValue={subject}
            handleOnChange={handleOnChange}
          />

          <Input
            label="Message"
            id="message"
            textArea
            errorMsg={
              errors.message &&
              dirty.message && <span className="error">{errors.message}</span>
            }
            inputValue={message}
            handleOnChange={handleOnChange}
          />

          <div className="d-flex align-items-center justify-content-center">
            {loadingRequest ? (
              <Spinner />
            ) : (
              <Button
                text="Send"
                type="submit"
                className="secondary"
                disabled={disable}
                icon={<EnvelopeFill />}
              />
            )}
          </div>
        </form>
      </Card>
    </div>
  );
};

export default ContactForm;
