import { INavlist } from './models';

const navList: Array<INavlist> = [
  {
    link: '/',
    text: 'home'
  },
  {
    link: '/author',
    text: 'author'
  },
  {
    link: '/book',
    text: 'book'
  },
  {
    link: '/videos',
    text: 'videos'
  },
  {
    link: '/reviews',
    text: 'reviews'
  },
  {
    link: '/blog',
    text: 'blog'
  },
  {
    link: '/faq',
    text: 'faq'
  },
  {
    link: '/contact',
    text: 'contact'
  }
];

export default navList;
