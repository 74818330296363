import * as React from 'react';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';

import Layout from './src/layout';
import store from './src/store';

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);
