import * as React from 'react';
import { Moon, Sun } from 'react-bootstrap-icons';

import { ThemeContext, Theme } from '../../context/theme';

const ThemeChange: React.FC = () => {
  const { theme, handleThemeChange } = React.useContext(ThemeContext);

  return (
    <div id="theme-change" className={theme}>
      {!theme || theme === Theme.LIGHT ? (
        <Moon
          size={20}
          onClick={() => handleThemeChange(Theme.DARK)}
          title="Dark mode"
        />
      ) : (
        <Sun
          size={20}
          onClick={() => handleThemeChange(Theme.LIGHT)}
          title="Light mode"
        />
      )}
    </div>
  );
};

export default ThemeChange;
