import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { uppercaseFirstLetter } from '../../../../helpers';
import { AppDispatch, RootState } from '../../../../store';
import {
  createEditDynamicContent,
  getAllContent,
  getDynamicContentByPageName
} from '../../../../store/reducers/dynamicContent';
import {
  IRow,
  ApiDynamicContentType,
  DynamicContentTypeCategory
} from '../models';

import DynamicContent from './main';
import reducer from './reducer';

import './style.scss';

interface Props {
  pageName: string;
  category: DynamicContentTypeCategory;
}

const SetupDynamicContent: React.FC<Props> = ({ pageName, category }) => {
  const apiDispatch = useDispatch<AppDispatch>();
  const dynamicState = useSelector((state: RootState) => state.dynamic);
  const authState = useSelector((state: RootState) => state.auth);

  const [apiMessage, setApiMessage] = React.useState<string | null>(null);

  const [NEW_DYNAMIC_CONTENT, stateDispath] = React.useReducer(reducer, []) as [
    IRow[],
    React.Dispatch<any>
  ];

  const [loadingRequest, setLoadingRequest] = React.useState<boolean>(false);

  const callDynamicContentByPageName = React.useCallback(() => {
    apiDispatch(getDynamicContentByPageName(pageName)).then((res) => {
      if (res.payload) {
        const dynamicData = res.payload as {
          dynamic?: ApiDynamicContentType;
        };
        stateDispath({
          data: dynamicData.dynamic?.container ?? null,
          type: 'GET-ALL'
        });
      } else {
        setApiMessage(
          `No content found for ${uppercaseFirstLetter(pageName)} page`
        );
      }
      apiDispatch(getAllContent());
    });
  }, [pageName]);

  const handleSave = React.useCallback(() => {
    const savePayload = {
      _id: dynamicState?.dynamic?._id,
      category: DynamicContentTypeCategory.PAGE,
      container: NEW_DYNAMIC_CONTENT,
      pageName,
      user: {
        image: authState?.user?.image,
        name: authState?.user?.name,
        userId: authState?.user?._id
      }
    };

    setLoadingRequest(true);
    apiDispatch(createEditDynamicContent(savePayload)).then(() => {
      callDynamicContentByPageName();
      setLoadingRequest(false);
    });
  }, [NEW_DYNAMIC_CONTENT, pageName]);

  React.useEffect(() => {
    callDynamicContentByPageName();
  }, [pageName, loadingRequest]);

  return (
    <DynamicContent
      apiMessage={apiMessage}
      category={DynamicContentTypeCategory.PAGE}
      dynamicState={dynamicState.dynamic}
      handleSave={handleSave}
      loadingRequest={loadingRequest}
      NEW_DYNAMIC_CONTENT={NEW_DYNAMIC_CONTENT}
      pageName={pageName}
      stateDispath={stateDispath}
    />
  );
};

export default SetupDynamicContent;
