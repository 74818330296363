import * as React from 'react';
import { TrashFill, PlusCircle } from 'react-bootstrap-icons';

import { Input, Button, SelectOption } from '../../../../..';
import { ThemeContext } from '../../../../../../context/theme';
import { ITable } from '../../../models';
import InputPublish from '../../helperComponents/inputPublish';
import { availableOrders } from '../../helpers';

import reducer from './reducer';

interface Props {
  colActive: boolean | null;
  colData: ITable;
  colId: number;
  uniqueId: string;
  updateCol: (
    colActive: boolean | null,
    colData: ITable,
    colId: number,
    uniqueId: string
  ) => void;
}

const initialItem: ITable = {
  id: 1,
  value: {
    tableBody: [
      {
        tr: [
          { content: '', tdId: 1 },
          { content: '', tdId: 2 },
          { content: '', tdId: 3 }
        ],
        trId: 1,
        trOrder: 1
      }
    ],
    tableHead: [
      { content: '', thId: 1 },
      { content: '', thId: 2 },
      { content: '', thId: 3 }
    ],
    tableId: 1
  }
};

const TableComponent: React.FC<Props> = ({
  colActive,
  colData,
  colId,
  uniqueId,
  updateCol
}) => {
  const { theme } = React.useContext(ThemeContext);
  const [active, setActive] = React.useState<boolean | null>(colActive);
  const [table, stateDispath] = React.useReducer(
    reducer,
    colData || initialItem
  ) as [ITable, React.Dispatch<any>];

  React.useEffect(() => {
    updateCol(active, table, colId, uniqueId);
  }, [active, table, colId, uniqueId, updateCol]);

  return (
    <div className="component">
      <InputPublish
        id={uniqueId + colId}
        active={active}
        setActive={setActive}
      />
      <table className={`table table-striped table-hover table-${theme}`}>
        <thead className="table-head">
          <tr>
            <th scope="col">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: 38 }}
              >
                Order
              </div>
            </th>
            {table.value.tableHead.map((t, indexHead: number) => (
              <th scope="col" key={t.thId}>
                {indexHead > 1 && (
                  <TrashFill
                    className="remove-item"
                    size={20}
                    onClick={() =>
                      stateDispath({
                        thId: t.thId,
                        type: 'REMOVE-TABLE-HEAD-ELEMENT'
                      })
                    }
                  />
                )}
                <Input
                  label="Add title"
                  id={`th_${t.thId}`}
                  type="text"
                  maxLength={12}
                  errorMsg={
                    t.content &&
                    t.content.length <= 3 && (
                      <span className="error">
                        Title length is less than 3 characters
                      </span>
                    )
                  }
                  inputValue={t.content}
                  handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    stateDispath({
                      content: e.target.value,
                      thId: t.thId,
                      type: 'UPDATE-HEADER-TH'
                    })
                  }
                />
              </th>
            ))}
            {table.value.tableHead.length < 5 && (
              <th scope="col">
                <span className="icon">
                  <PlusCircle
                    className="add-item"
                    size={20}
                    onClick={() => stateDispath({ type: 'ADD-COLUMN' })}
                  />
                </span>
              </th>
            )}
          </tr>
        </thead>
        <tbody className="table-body">
          {[...table.value.tableBody]
            .sort((a, b) => a.trOrder - b.trOrder)
            .map((t, indexBody: number) => (
              <tr key={t.trId}>
                <td>
                  <SelectOption
                    options={availableOrders(table.value.tableBody)}
                    value={t.trOrder}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      stateDispath({
                        trId: t.trId,
                        trOrder: event.target.value,
                        type: 'SET-ORDER'
                      })
                    }
                  />
                </td>
                {t.tr.map((item) => (
                  <td key={item.tdId}>
                    <Input
                      label="Add content"
                      id={`td_${item.tdId}`}
                      type="text"
                      errorMsg={
                        item.content &&
                        item.content.length <= 3 && (
                          <span className="error">
                            Content length is less than 3 characters
                          </span>
                        )
                      }
                      inputValue={item.content}
                      handleOnChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) =>
                        stateDispath({
                          content: e.target.value,
                          tdId: item.tdId,
                          trId: t.trId,
                          type: 'UPDATE-BODY-TD'
                        })
                      }
                    />
                  </td>
                ))}
                {indexBody > 0 && (
                  <td>
                    <span className="icon">
                      <TrashFill
                        className="remove-item"
                        size={20}
                        onClick={() =>
                          stateDispath({ trId: t.trId, type: 'REMOVE-TR' })
                        }
                      />
                    </span>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <Button
        text="Add Tr"
        className="tertiary mt-3"
        buttonClick={() => stateDispath({ type: 'ADD-TR' })}
      />
    </div>
  );
};

export default TableComponent;
