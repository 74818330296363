import * as React from 'react';

import './style.scss';

interface Props {
  text: string;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  buttonClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  icon?: React.ReactNode;
}

const Button: React.FC<Props> = ({
  text,
  type = 'button',
  className = 'primary',
  buttonClick,
  disabled,
  icon
}) => {
  return (
    <button
      type={type}
      className={`btn btn-${className}`}
      onClick={buttonClick}
      disabled={disabled}
    >
      {icon}
      <span>{text}</span>
    </button>
  );
};

export default Button;
