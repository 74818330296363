import {
  OptionTypes,
  IRow,
  ICol,
  ICarousel,
  IImage,
  DynamicContentTypeCategory
} from '../models';

import { complementMap } from './reducer';

export const availableTags: OptionTypes[] = [
  { label: 'H1', value: 'h1' },
  { label: 'H2', value: 'h2' },
  { label: 'H3', value: 'h3' },
  { label: 'H4', value: 'h4' },
  { label: 'H5', value: 'h5' },
  { label: 'H6', value: 'h6' }
];

export const availableWidths: OptionTypes[] = [
  { label: 'Full', value: '100' },
  { label: 'Half', value: '50' }
];

export const availableTextAligns: OptionTypes[] = [
  { label: 'Left', value: 'start' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'end' }
];

const commonAvailableComponents: OptionTypes[] = [
  { label: 'Textarea', value: 'textarea' },
  { label: 'Image', value: 'image' },
  { label: 'Button', value: 'button' }
];

const pageAvailableTypes: OptionTypes[] = [
  { label: 'Accordion', value: 'accordion' },
  { label: 'Book', value: 'book' },
  { label: 'Carousel', value: 'carousel' },
  { label: 'Table', value: 'table' },
  { label: 'Text', value: 'text' },
  { label: 'Video', value: 'video' }
];

export const availableComponents = (category: DynamicContentTypeCategory) => {
  return category === DynamicContentTypeCategory.NEWSLETTER
    ? commonAvailableComponents
    : [...commonAvailableComponents, ...pageAvailableTypes];
};

export const availableBookCovers: OptionTypes[] = [
  { label: 'Legira - The Secrets Unfold', value: 'the-secrets-unfold.jpg' }
];

export const hasEmptyImageData = (array: IRow[]) => {
  return array.some((row: IRow) => {
    return row.row.some((col: ICol) => {
      let result = false;
      if (col.colType === 'image' && col.colData) {
        result =
          !(col.colData as IImage).value.image &&
          (col.colData as IImage).value.imagePath === '';
      } else if (col.colType === 'carousel' && col.colData) {
        result = (col.colData as ICarousel[]).some(
          (item) => !item.value.image && item.value.imagePath === ''
        );
      }
      return result; // Return false for other colType values
    });
  });
};

export const availableOrders = (array: any) => {
  return Array.from({ length: array.length }, (_, i) => i + 1).map(
    (option: number) => ({ label: option, value: option })
  );
};

export const availableWidth = () => {
  const colWidthArray = Object.keys(complementMap).map((item) => Number(item));
  return colWidthArray.map((option: number) => ({
    label: option,
    value: option
  }));
};

export const DYNAMIC_DATA = [];
