import * as React from 'react';

import { Button, Image } from '../..';

interface ImageUploadProps {
  value: File | null;
  onChange: (file: File | null) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ value, onChange }) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [filePreview, setFilePreview] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (value) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFilePreview(e.target?.result as string);
      };
      reader.readAsDataURL(value);
    }
  }, [value]);

  const deleteFile = () => {
    setFilePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    onChange(null);
  };

  const selectImgFile = () => {
    const fileInput = fileInputRef.current;
    const imgFile = fileInput?.files?.[0];

    if (imgFile) {
      const acceptedTypes = ['image/jpeg', 'image/png'];
      const maxSize = 1048576; // 1MB

      if (acceptedTypes.includes(imgFile.type) && imgFile.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setFilePreview(e.target?.result as string);
        };
        reader.readAsDataURL(imgFile);
        onChange(imgFile);
      } else {
        alert(
          'The selected image file is either not a JPEG/PNG or exceeds the maximum file size of 1MB.'
        );
        deleteFile();
      }
    }
  };

  return (
    <div className="image-upload">
      {filePreview && <Image src={filePreview} />}

      <div className="mt-3">
        <input
          className="form-control"
          ref={fileInputRef}
          type="file"
          id="formFile"
          accept=".jpg,.jpeg,.png"
          onChange={selectImgFile}
        />
      </div>

      {filePreview && (
        <Button
          text="Remove Image"
          className="danger mt-3"
          buttonClick={deleteFile}
        />
      )}
    </div>
  );
};

export default ImageUpload;
