export const uppercaseFirstLetter = (value: string | undefined) => {
  const item = value ?? '';
  return item?.charAt(0).toUpperCase() + item?.slice(1);
};

export const windowInnerWidth = 1115;
export const windowSize =
  typeof window !== 'undefined' && window.innerWidth <= windowInnerWidth;

export { default as isMobile } from './isMobile';
export { default as validationSchema } from './validationSchema';
export { default as switchSocialIcons } from './switchSocialIcons';

export const scrollToSection = (section: string, scrollPos: number) => {
  const scrollToSection = document.getElementById(section);
  if (scrollToSection && typeof window !== 'undefined') {
    const y = scrollToSection.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      behavior: 'smooth',
      top: y - scrollPos
    });
  }
};

export const isInViewport = (elem: HTMLElement | null) => {
  if (!elem) return false;

  const bounding = elem.getBoundingClientRect();
  const inViewport =
    typeof window !== 'undefined' &&
    bounding.top < window.innerHeight &&
    bounding.bottom > 0 &&
    bounding.left < window.innerWidth &&
    bounding.right > 0;

  return inViewport;
};

export const renderDate = (date: string) => {
  const reviewDate = new Date(date);

  const day = String(reviewDate.getDate()).padStart(2, '0'); // Day with zero padding
  const month = String(reviewDate.getMonth() + 1).padStart(2, '0'); // Month with zero padding
  const year = reviewDate.getFullYear(); // Full year

  return `${day}/${month}/${year}`;
};
