import * as React from 'react';

import { ITabs } from '../models';

import './style.scss';

interface Props {
  data: Array<ITabs>;
  page?: string;
  usersFirstPage?: string;
}

const TabsHorizontal: React.FC<Props> = ({ data, page, usersFirstPage }) => {
  const defaultComponent = page ?? usersFirstPage ?? data[0].title;
  const [component, setComponent] = React.useState<string>(defaultComponent);

  const renderPage = React.useCallback(() => {
    return data.find((item: ITabs) => item.title === component);
  }, [component]);

  return (
    <React.Fragment>
      <ul className="nav nav-tabs" id="nav-tabs">
        {data.map((item: ITabs) => (
          <li className="nav-item" key={item.title}>
            <button
              type="button"
              className={`nav-link ${item.title === component ? 'active' : ''}`}
              onClick={() => setComponent(item.title)}
            >
              {item.title}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content">{renderPage()?.component}</div>
    </React.Fragment>
  );
};

export default TabsHorizontal;
