import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import { AppDispatch, RootState } from '../../../store';
import { clearMessage } from '../../../store/reducers/message';

import 'react-toastify/dist/ReactToastify.css';
import './style.scss';

export const API_MESSAGE_CLEAR_INTERVAL = 6000;

const Message: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const message = useSelector((state: RootState) => state.message);

  const notify = () => {
    toast(message.message, {
      className: 'text-center',
      position: toast.POSITION.TOP_CENTER
    });
  };

  React.useEffect(() => {
    notify();
    setTimeout(() => {
      dispatch(clearMessage());
    }, API_MESSAGE_CLEAR_INTERVAL);
  }, [message]);

  return <ToastContainer />;
};

export default Message;
