import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { ISubscriber } from '../../components/molecules/banner/newsletterSubscribe/subscribeToNewsletter';
import { IDetectSubscriber } from '../../hooks/useSubscriberVisited';
import ApiService from '../service';

import { setMessage } from './message';

export const subscribeToNewsletter = createAsyncThunk(
  'subscribe/subscribe',
  async (params: ISubscriber, thunkAPI) => {
    try {
      const result = await ApiService.postRequest(
        '/api/subscribe',
        JSON.stringify(params)
      );

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        localStorage.setItem('subscribed', 'true');
        thunkAPI.dispatch(setMessage(result.data.message));
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const detectSubscriber = createAsyncThunk(
  'subscribe/info',
  async (params: IDetectSubscriber, thunkAPI) => {
    try {
      if (!params) {
        return;
      }

      await ApiService.postRequest('/api/subscribe/info', {
        ...params
      });
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSubscribers = createAsyncThunk(
  'subscribe/getSubscribers',
  async (_, thunkAPI) => {
    try {
      const result = await ApiService.getRequest('/api/subscribe');

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else {
        return { subscribers: result };
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteSubscriber = createAsyncThunk(
  'subscribe/deleteSubscriber',
  async (param: string, thunkAPI) => {
    try {
      const result = await ApiService.deleteRequest('/api/subscribe', {
        userId: param
      });

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const unsubscribe = createAsyncThunk(
  'subscribe/unsubscribe',
  async (param: string | null, thunkAPI) => {
    try {
      const result = await ApiService.deleteRequest(
        '/api/subscribe/unsubscribe',
        {
          email: param
        }
      );

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        localStorage.removeItem('subscribed');
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const confirmEmail = createAsyncThunk(
  'subscribe/confirmEmail',
  async (params: string, thunkAPI) => {
    try {
      const result = await ApiService.postRequest(
        '/api/subscribe/confirm-email',
        params
      );

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const confirmEmailReminder = createAsyncThunk(
  'subscribe/confirmEmailReminder',
  async (params: string, thunkAPI) => {
    const payload = {
      email: params
    };

    try {
      const result = await ApiService.postRequest(
        '/api/subscribe/confirm-email-reminder',
        JSON.stringify(payload)
      );

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface ISubscribersList {
  _id: string;
  email: string;
  name: string;
  validated: boolean;
  newsletterIds: string[];
  visited: {
    _id: string;
    at: string;
    pageId: string;
    pageName: string;
  }[];
}

interface ISubscribers {
  subscribers: ISubscribersList[] | null;
}

const initialState: ISubscribers = {
  subscribers: null
};

const subscribeSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getSubscribers.fulfilled, (state, action) => {
        state.subscribers = action?.payload?.subscribers;
      })
      .addCase(getSubscribers.rejected, (state, action) => {});
  },
  initialState,
  name: 'subscribe',
  reducers: {}
});

const { reducer } = subscribeSlice;
export default reducer;
