import * as React from 'react';

import { ThemeContext } from '../../../context/theme';
import {
  ITableItems,
  ITableItemsBody,
  ITableItemsBodyTr,
  ITableItemsHead
} from '../../organisms/dynamicContent/models';

import './style.scss';

interface Props {
  data: ITableItems;
}

const Table: React.FC<Props> = ({ data }) => {
  const { theme } = React.useContext(ThemeContext);
  const { tableHead, tableBody } = data;

  return (
    <div className="table-responsive">
      <table className={`table table-striped table-hover table-${theme}`}>
        <thead className="table-head">
          <tr>
            {tableHead.map((t: ITableItemsHead) => (
              <th scope="col" key={t.thId}>
                {t.content}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...tableBody]
            .sort((a, b) => a.trOrder - b.trOrder)
            .map((t: ITableItemsBody) => (
              <tr key={t.trId}>
                {t.tr.map((item: ITableItemsBodyTr) => (
                  <td
                    data-label={
                      tableHead.find((label) => label.thId === item.tdId)
                        ?.content
                    }
                    key={item.tdId}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    ></span>
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
