import * as React from 'react';

import './style.scss';

interface Props {
  children: React.ReactNode;
  pageTitle: string;
}

const Main: React.FC<Props> = ({ children, pageTitle }) => {
  return (
    <main className="container-fluid">
      {pageTitle && (
        <div className="page-title-overlay">
          {pageTitle === 'Author' ? `The ${pageTitle}` : pageTitle}
        </div>
      )}
      {children}
    </main>
  );
};

export default Main;
