import { navigate } from 'gatsby';
import * as React from 'react';
import { ArrowUpCircle } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';

import {
  CookieBanner,
  Image,
  BannerSubscribeToNewsletter,
  SubscribeToNewsletter,
  SocialLinks,
  UsefulLinks
} from '../../components';
import { ThemeContext } from '../../context/theme';
import { isInViewport } from '../../helpers';
import { useSiteMetadata } from '../../hooks';
import { RootState } from '../../store';

import './style.scss';

interface Props {
  getFooterHeight: (value: number) => void;
  pathname: string;
}

const Footer: React.FC<Props> = ({ getFooterHeight, pathname }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const [showScrollToTop, setShowScrollToTop] = React.useState<boolean>(false);
  const [isFooterInViewport, setIsFooterInViewport] =
    React.useState<boolean>(true);

  const { theme } = React.useContext(ThemeContext);
  const { title } = useSiteMetadata();
  const ref = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (ref?.current) {
      getFooterHeight(ref?.current?.offsetHeight);
    }
  }, [ref?.current?.offsetHeight]);

  const scrollToTop = (): void => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      });
    }
  };

  const handleScroll = React.useCallback((): void => {
    if (typeof window !== 'undefined' && ref.current) {
      const footerInView = isInViewport(ref.current);
      setIsFooterInViewport(footerInView);
      setShowScrollToTop(window.scrollY >= 350);
    }
  }, [isInViewport]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const footerCondition =
    pathname !== 'authentication' && pathname !== 'subscribe';

  const cookieBannerCondition =
    footerCondition && pathname !== 'privacy-policy' && !authState.user;

  const newsletterCondition =
    footerCondition &&
    !authState.user &&
    typeof localStorage !== 'undefined' &&
    !localStorage.getItem('subscribed');

  return (
    <React.Fragment>
      {footerCondition && (
        <footer id="footer" ref={ref}>
          <div className="footer-top">
            <div className="row">
              <div className="col-sm-4">
                <UsefulLinks />
                <hr />
              </div>
              <div className="col-sm-4">
                <SocialLinks />
                <hr />
              </div>
              <div className="col-sm-4">
                <SubscribeToNewsletter />
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <small className="copyright">
              <span onDoubleClick={() => navigate('/authentication')}>
                Copyright
              </span>{' '}
              © {new Date().getFullYear()} {title}. All rights reserved.
            </small>

            <div className="csaba-timar">
              <a
                href="https://csabatimar.com/"
                target="_blank"
                title="csabatimar.com"
                rel="noopener noreferrer"
              >
                <Image
                  src={
                    require(`../../images/csabatimar-${theme || 'light'}.svg`)
                      .default
                  }
                  alt="csabatimar.com"
                />
              </a>
            </div>
          </div>
        </footer>
      )}

      {newsletterCondition && (
        <BannerSubscribeToNewsletter isFooterInViewport={isFooterInViewport} />
      )}

      {cookieBannerCondition && <CookieBanner />}

      {showScrollToTop &&
        pathname !== 'authentication' &&
        pathname !== 'subscribe' && (
          <div className="scroll-to-top">
            <div onClick={() => scrollToTop()}>
              <ArrowUpCircle size={30} />
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

export default Footer;
