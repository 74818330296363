import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ApiService from '../service';

import { setMessage } from './message';

export interface ISocialLinks {
  active: boolean;
  id: number;
  link: string;
  title: string;
}

export interface SettingsType {
  _id?: string;
  settingsType: string;
  container: ISocialLinks[];
}

export const createEditSettings = createAsyncThunk(
  'settings/createSettings',
  async (params: SettingsType, thunkAPI) => {
    try {
      let result;

      if (params._id) {
        result = await ApiService.patchRequest(
          `/api/settings/${params._id}`,
          params
        );
      } else {
        result = await ApiService.postRequest('/api/settings', params);
      }

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else if (typeof result.data !== 'undefined') {
        const { data } = result;
        thunkAPI.dispatch(setMessage(data.message));
        return { message: data.message };
      } else if (result.response.data) {
        thunkAPI.dispatch(setMessage(result.response.data.message));
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSettings = createAsyncThunk(
  'settings/getSettings',
  async (_, thunkAPI) => {
    try {
      const result = await ApiService.getRequest('/api/settings');

      if (typeof result.message !== 'undefined') {
        thunkAPI.dispatch(setMessage(result.message));
      } else {
        return { settings: result };
      }
    } catch (error: unknown) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface RootState {
  settings: SettingsType[] | null;
}

const initialState: RootState = {
  settings: null
};

const settingsSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(createEditSettings.fulfilled, (state, action) => {})
      .addCase(createEditSettings.rejected, (state, action) => {})
      .addCase(getSettings.fulfilled, (state, action) => {
        state.settings = action?.payload?.settings;
      })
      .addCase(getSettings.rejected, (state, action) => {});
  },
  initialState,
  name: 'settings',
  reducers: {}
});

const { reducer } = settingsSlice;
export default reducer;
