import * as React from 'react';
import { TrashFill } from 'react-bootstrap-icons';

import { Input, Button } from '../../../..';
import { IAccordion, IAccordionItems } from '../../models';
import InputPublish from '../helperComponents/inputPublish';

interface Props {
  colActive: boolean | null;
  colData: IAccordion;
  colId: number;
  uniqueId: string;
  updateCol: (
    colActive: boolean | null,
    colData: IAccordion,
    colId: number,
    uniqueId: string
  ) => void;
}

const initialItem: IAccordion = {
  category: '',
  id: 1,
  value: [
    {
      answer: '',
      question: '',
      questionId: 1
    }
  ]
};

const AccordionComponent: React.FC<Props> = ({
  colActive,
  colData,
  colId,
  uniqueId,
  updateCol
}) => {
  const [active, setActive] = React.useState<boolean | null>(colActive);
  const [accordion, setAccordion] = React.useState<IAccordion>(
    colData || initialItem
  );

  const handleAddItem = () => {
    setAccordion((prevState: IAccordion) => {
      if (prevState) {
        const questionId =
          accordion.value.length > 0
            ? accordion.value[accordion.value.length - 1].questionId + 1
            : 1;
        const newItem: IAccordionItems = {
          answer: '',
          question: '',
          questionId: questionId
        };

        return { ...prevState, value: [...prevState.value, newItem] };
      }

      return prevState;
    });
  };

  const handleRemoveItem = (id: number) => {
    setAccordion((prevState: IAccordion) => {
      return {
        ...prevState,
        value: prevState.value.filter(
          (item: IAccordionItems) => item.questionId !== id
        )
      };
    });
  };

  const handlePropertyChange = (
    id: number,
    property: keyof IAccordionItems,
    value: string
  ) => {
    setAccordion((prevState: IAccordion) => {
      return {
        ...prevState,
        value: prevState.value.map((item: IAccordionItems) => {
          return item.questionId === id ? { ...item, [property]: value } : item;
        })
      };
    });
  };

  React.useEffect(() => {
    updateCol(active, accordion, colId, uniqueId);
  }, [active, accordion, colId, uniqueId, updateCol]);

  return (
    <div className="component">
      <InputPublish
        id={uniqueId + colId}
        active={active}
        setActive={setActive}
      />
      <Input
        label="Category Title"
        id={`category_${accordion.id}`}
        type="text"
        errorMsg={
          accordion.category &&
          accordion.category.length <= 3 && (
            <span className="error">
              Category length is less than 3 characters
            </span>
          )
        }
        inputValue={accordion.category}
        handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setAccordion({
            category: e.target.value,
            id: accordion.id,
            value: accordion.value
          })
        }
      />

      {accordion.value.map((item: IAccordionItems) => (
        <div
          className="component-item mb-4 px-4 pb-2 pt-4"
          key={item.questionId}
        >
          <TrashFill
            className="remove-item"
            size={20}
            onClick={() => handleRemoveItem(item.questionId)}
          />
          <Input
            label="Add Question"
            id={`question_${item.questionId}`}
            type="text"
            errorMsg={
              item.question &&
              item.question.length <= 3 && (
                <span className="error">
                  Question length is less than 3 characters
                </span>
              )
            }
            inputValue={item.question}
            handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePropertyChange(item.questionId, 'question', e.target.value)
            }
          />
          <Input
            label="Add Answer"
            id={`answer_${item.questionId}`}
            textArea
            errorMsg={
              item.answer &&
              item.answer.length <= 3 && (
                <span className="error">
                  Answer length is less than 3 characters
                </span>
              )
            }
            inputValue={item.answer}
            handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handlePropertyChange(item.questionId, 'answer', e.target.value)
            }
          />
        </div>
      ))}
      <Button
        text="Add Item"
        className="tertiary"
        buttonClick={handleAddItem}
      />
    </div>
  );
};

export default AccordionComponent;
