import * as React from 'react';

import { Input, SelectOption } from '../../../..';
import {
  DynamicContentTypeCategory,
  ILinkButton,
  OptionTypes
} from '../../models';
import InputPublish from '../helperComponents/inputPublish';
import { availableTextAligns } from '../helpers';

interface Props {
  category: DynamicContentTypeCategory;
  colActive: boolean | null;
  colData: ILinkButton;
  colId: number;
  uniqueId: string;
  updateCol: (
    colActive: boolean | null,
    colData: ILinkButton,
    colId: number,
    uniqueId: string
  ) => void;
}

const LinkButtonComponent: React.FC<Props> = ({
  category,
  colActive,
  colData,
  colId,
  uniqueId,
  updateCol
}) => {
  const [active, setActive] = React.useState<boolean | null>(colActive);

  const [button, setButton] = React.useState<ILinkButton>({
    align: colData?.align || 'center',
    external: colData?.external || false,
    id: colId,
    linkTo: colData?.linkTo || '',
    type: colData?.type || 'primary',
    value: colData?.value || ''
  });

  const updateButton = (key: keyof ILinkButton, value: any) => {
    setButton((prevButton) => ({
      ...prevButton,
      [key]: value
    }));
  };

  const buttonTypes: OptionTypes[] = [
    { label: 'Primary', value: 'primary' },
    { label: 'Primary active', value: 'primary active' },
    { label: 'Secondary', value: 'secondary' },
    { label: 'Secondary active', value: 'secondary active' }
  ];

  React.useEffect(() => {
    updateCol(active, button, colId, uniqueId);
  }, [active, button, colId, uniqueId, updateCol]);

  const isNotNewsletter = category !== 'newsletter';

  return (
    <div className="component-item">
      {isNotNewsletter && (
        <InputPublish
          id={uniqueId + colId}
          active={active}
          setActive={setActive}
        />
      )}
      {isNotNewsletter ? (
        <div className="row">
          <div className="col-sm-12 mb-3">
            <Input
              label={
                button.external
                  ? 'External link'
                  : 'Internal link (use relative path)'
              }
              type="checkbox"
              id={`external-internal-${colId}`}
              inputValue={button.external}
              handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateButton('external', e.target.checked)
              }
            />
          </div>
          <div className="col-sm-6">
            <SelectOption
              label="Button type"
              options={buttonTypes}
              value={button.type}
              className="mb-3"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                updateButton('type', e.target.value)
              }
            />
          </div>

          <div className="col-sm-6">
            <SelectOption
              label="Button Align"
              options={availableTextAligns}
              value={button.align}
              className="mb-3"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                updateButton('align', e.target.value)
              }
            />
          </div>
        </div>
      ) : (
        <p className="text-center h6 mb-3">
          If the link points to your website, remove / from the end of the link
        </p>
      )}
      <div className="row">
        <div className="col-sm-6">
          <Input
            label="Add Text"
            id={`button_text_${uniqueId + colId}`}
            type="text"
            errorMsg={
              button.value &&
              button.value.length <= 3 && (
                <span className="error">
                  Text length is less than 3 characters
                </span>
              )
            }
            inputValue={button.value}
            handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateButton('value', e.target.value)
            }
          />
        </div>
        <div className="col-sm-6">
          <Input
            label="Add Link"
            id={`button_link_${uniqueId + colId}`}
            type="text"
            errorMsg={
              button.linkTo &&
              button.linkTo.length <= 3 && (
                <span className="error">
                  Link length is less than 3 characters
                </span>
              )
            }
            inputValue={button.linkTo}
            handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateButton('linkTo', e.target.value)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default LinkButtonComponent;
