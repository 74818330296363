import * as React from 'react';
import { ChevronDown } from 'react-bootstrap-icons';

import { OptionTypes } from '../../organisms/dynamicContent/models';

import './style.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  label?: string;
  multiple?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: OptionTypes[];
  value: any;
}

const SelectOption: React.FC<Props> = ({
  className = '',
  disabled,
  icon,
  label,
  multiple,
  onChange,
  options,
  value
}) => {
  const removeSpecialCharactersAndWhitespace = (str: string) => {
    // Remove special characters and whitespace using regular expression
    const cleanedString = str.replace(/[^a-zA-Z0-9]/g, '');

    // Generate a random number between 1000 and 9999
    const randomNumber = Math.floor(Math.random() * 9000) + 1000;

    // Concatenate the random number to the cleaned string
    return cleanedString + randomNumber;
  };
  const idString = label ? removeSpecialCharactersAndWhitespace(label) : '';
  return (
    <div className={`select-option ${className}`}>
      {label && <label htmlFor={idString}>Select {label}</label>}
      {!multiple && <ChevronDown size={20} className="chevron-down" />}
      {icon && <div className="icon">{icon}</div>}
      <select
        id={idString}
        className={`form-select ${icon ? 'ps-4' : ''}`}
        value={value}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectOption;
