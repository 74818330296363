import { Link, navigate } from 'gatsby';
import * as React from 'react';
import { BoxArrowRight, List } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux';

import { Logo, ShowWithAnimation, UserAvatar } from '../../components';
import { useSiteMetadata } from '../../hooks';
import { AppDispatch, RootState } from '../../store';
import { logout } from '../../store/reducers/auth';
import MainMenu from '../navigation/mainMenu/mainMenu';

import SearchForm from './searchForm/searchForm';
import ThemeChange from './themeChange';

import './style.scss';

interface Props {
  availableMenuList: (string | undefined)[] | undefined;
  enableSideNav: boolean;
  pathname?: string;
  setToggleNav: (value: boolean) => void;
}

const Header: React.FC<Props> = ({
  availableMenuList,
  enableSideNav,
  pathname,
  setToggleNav
}) => {
  const { title } = useSiteMetadata();
  const dispatch = useDispatch<AppDispatch>();
  const authState = useSelector((state: RootState) => state.auth);
  const [dropdown, setDropdown] = React.useState<boolean>(false);

  React.useEffect(() => {
    setDropdown(false);
  }, [pathname]);

  return (
    <header id="header">
      <div className="open-menu">
        {enableSideNav && (
          <List size={25} onClick={() => setToggleNav(true)} className="me-3" />
        )}
        <div className="logo-container">
          <Logo size={60} />
          <h4 onClick={() => navigate('/')} className="title">
            {title}
          </h4>
        </div>
      </div>
      <div className="content">
        {!enableSideNav && (
          <MainMenu
            availableMenuList={availableMenuList}
            setToggleNav={setToggleNav}
            position="in-header"
          />
        )}
        <ThemeChange />
        <SearchForm />
        {authState.user && (
          <div className="header-title">
            <div onClick={() => setDropdown(!dropdown)} className="user-box">
              <UserAvatar size={35} />
            </div>

            <ShowWithAnimation isMounted={dropdown}>
              <div className="dropdown">
                <ul>
                  <li>
                    <Link to="/profile">My Profile</Link>
                  </li>
                  {authState?.user?.pages &&
                    authState.user.pages.length > 0 && (
                      <li>
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                    )}
                  {authState.user.isAdmin && (
                    <React.Fragment>
                      <li>
                        <Link to="/users">Users</Link>
                      </li>
                      <li>
                        <Link to="/settings">Settings</Link>
                      </li>
                    </React.Fragment>
                  )}
                  <li onClick={() => dispatch(logout())} className="logout">
                    <BoxArrowRight title="Logout" size={20} />
                    Logout
                  </li>
                </ul>
              </div>
            </ShowWithAnimation>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
