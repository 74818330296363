import * as React from 'react';

import { Input, ImageUpload, Image, SelectOption } from '../../../..';
import { DynamicContentTypeCategory, IImage } from '../../models';
import InputPublish from '../helperComponents/inputPublish';
import {
  availableTags,
  availableTextAligns,
  availableWidths
} from '../helpers';

interface Props {
  category: DynamicContentTypeCategory;
  colActive: boolean | null;
  colData: IImage;
  colId: number;
  uniqueId: string;
  updateCol: (
    colActive: boolean | null,
    colData: IImage,
    colId: number,
    uniqueId: string
  ) => void;
}

const ImageComponent: React.FC<Props> = ({
  category,
  colActive,
  colData,
  colId,
  uniqueId,
  updateCol
}) => {
  const [active, setActive] = React.useState<boolean | null>(colActive);
  const [image, setImage] = React.useState<IImage>({
    id: colId,
    value: {
      align: colData?.value.align || 'center',
      image: null,
      imageName: colData?.value.imageName || '',
      imagePath: colData?.value.imagePath || '',
      tag: colData?.value.tag || 'h2',
      textarea: colData?.value.textarea || '',
      title: colData?.value.title || '',
      width: colData?.value.width || '50'
    }
  });

  const updateValues = (newValues: Partial<IImage['value']>) => {
    setImage((prevImage) => ({
      id: colId,
      value: {
        ...prevImage.value,
        ...newValues
      }
    }));
  };

  const handleChange = (key: keyof IImage['value'], newValue: string) => {
    updateValues({ [key]: newValue });
  };

  const handleImageChange = (file: File | null) => {
    updateValues({
      image: file,
      imageName: `${file?.name}_${file?.size}`,
      imagePath: ''
    });
  };

  React.useEffect(() => {
    updateCol(active, image, colId, uniqueId);
  }, [active, image, colId, uniqueId, updateCol]);

  const isNotNewsletter = category !== 'newsletter';

  return (
    <div className="component-item">
      {isNotNewsletter && (
        <InputPublish
          id={uniqueId + colId}
          active={active}
          setActive={setActive}
        />
      )}
      <div className="row">
        <div className="col-sm-6">
          {isNotNewsletter && (
            <div>
              <SelectOption
                label="Tag"
                options={availableTags}
                value={image.value.tag}
                className="mb-3"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  handleChange('tag', e.target.value)
                }
              />
              <SelectOption
                label="Title Align"
                options={availableTextAligns}
                value={image.value.align}
                className="mb-3"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  handleChange('align', e.target.value)
                }
              />
            </div>
          )}
          <SelectOption
            label="Image Width"
            options={availableWidths}
            value={image.value.width}
            className="mb-3"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleChange('width', e.target.value)
            }
          />
          <Input
            label="Add Title"
            id={`title_${colId}`}
            type="text"
            errorMsg={
              image.value.title &&
              image.value.title.length <= 3 && (
                <span className="error">
                  Title length is less than 3 characters
                </span>
              )
            }
            inputValue={image.value.title}
            handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('title', e.target.value)
            }
          />
          <Input
            label="Add Textarea"
            id={`textarea_${colId}`}
            textArea
            errorMsg={
              image.value.textarea &&
              image.value.textarea.length <= 3 && (
                <span className="error">
                  Text length is less than 3 characters
                </span>
              )
            }
            inputValue={image.value.textarea}
            handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('textarea', e.target.value)
            }
          />
        </div>
        <div className="col-sm-6">
          {image.value.imagePath && (
            <Image
              src={`${process.env.GATSBY_API_URL}/${image.value.imagePath}`}
              width={image.value.width}
            />
          )}
          <ImageUpload value={image.value.image} onChange={handleImageChange} />
        </div>
      </div>
    </div>
  );
};

export default ImageComponent;
