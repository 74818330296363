import { Link } from 'gatsby';
import * as React from 'react';

import { useSiteMetadata } from '../../../../hooks';
import Button from '../../../atoms/button';

import './style.scss';

const CookieBanner: React.FC = () => {
  const { title } = useSiteMetadata();
  const [showCookieBanner, setShowCookieBanner] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShowCookieBanner(true);
    }, 2500);
  }, []);

  return (
    <div id="cookie-banner" className={showCookieBanner ? 'active' : ''}>
      <p>
        {title} uses cookies. By continuing to browse the site, you agree to our
        use of{' '}
        <Link to="/privacy-policy" className="privacy-policy-link">
          Privacy Policy
        </Link>
        .
      </p>
      <Button
        text="Agree"
        className="secondary"
        buttonClick={() => setShowCookieBanner(false)}
      />
    </div>
  );
};

export default CookieBanner;
