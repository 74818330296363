import * as React from 'react';

import { Input } from '../../../..';

interface Props {
  bgColor: boolean | undefined;
  id: string;
  label?: string;
  setBgColor: () => void;
}

const InputBgColor: React.FC<Props> = ({ bgColor, id, label, setBgColor }) => {
  return (
    <div className="mb-3 d-flex">
      <Input
        label={`${bgColor ? 'With Background' : 'No Background'} ${label || ''}`}
        type="checkbox"
        id={`bgColor-${id}`}
        inputValue={bgColor}
        handleOnChange={setBgColor}
      />
    </div>
  );
};

export default InputBgColor;
