import { v4 as uuidv4 } from 'uuid';

import { IRow, ICol } from '../models';

import { availableComponents } from './helpers';

const sortArray = (array: IRow[]) =>
  array.sort((a, b) => a.rowOrder - b.rowOrder);

const getAll = (action: any) => {
  return action.data ? sortArray([...action.data]) : [];
};

export const complementMap: Record<number, number> = {
  3: 9,
  4: 8,
  5: 7,
  6: 6,
  7: 5,
  8: 4,
  9: 3,
  // eslint-disable-next-line sort-keys
  12: 12
};

const generateColWidth = (targetSum: number) => {
  return complementMap[targetSum].toString();
};

const addRow = (state: IRow[], action: any) => {
  if (typeof window !== 'undefined') {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: document.body.scrollHeight
      });
    }, 100);
  }
  const rowOrder = state.length > 0 ? state[state.length - 1].rowOrder + 1 : 1;
  return [
    ...state,
    {
      row: [],
      rowBgColor: false,
      rowOrder: rowOrder,
      rowType: availableComponents(action.category)[0].value,
      uniqueId: uuidv4()
    }
  ];
};

const removeRow = (state: IRow[], action: any) => {
  return state.filter((item) => item.uniqueId !== action.uniqueId);
};

const rowBgColor = (state: IRow[], action: any) => {
  const result = state.map((row) =>
    row.uniqueId === action.uniqueId
      ? {
          ...row,
          rowBgColor: !row.rowBgColor
        }
      : row
  );
  return result;
};

const updateRowOrder = (state: IRow[], action: any) => {
  const result = state.map((row) =>
    row.uniqueId === action.uniqueId
      ? {
          ...row,
          rowOrder: action.order
        }
      : row
  );
  return sortArray(result);
};

const updateRowColType = (state: IRow[], action: any) => {
  return state.map((row) =>
    row.uniqueId === action.uniqueId
      ? {
          ...row,
          rowType: action.newColType
        }
      : row
  );
};

const addCol = (state: IRow[], action: any) => {
  return state.map((row) => {
    if (row.uniqueId === action.uniqueId) {
      // Check if the row has less than 2 columns before adding a new column
      if (row.row.length < 2) {
        const colId =
          row.row.length > 0 ? row.row[row.row.length - 1].colId + 1 : 1;
        const newCol: ICol = {
          colActive: true,
          colData: null, // Initialize as array or object based on row.rowType
          colId: colId,
          colOrder: colId,
          colType: row.rowType, // You might need to modify this to get the correct column type
          colWidth: '12'
        };
        return {
          ...row,
          row: [...row.row, newCol]
        };
      }
    }
    return row;
  });
};

const updateColOrder = (state: IRow[], action: any) => {
  return state.map(
    (row) =>
      // If the current row ID matches the target row ID
      row.uniqueId === action.uniqueId
        ? {
            ...row, // Copy the existing row properties
            row: row.row
              .map(
                (col) =>
                  // If the current column ID matches the target column ID
                  col.colId === action.colId
                    ? {
                        ...col, // Copy the existing column properties
                        colOrder: action.order
                      }
                    : col // Otherwise, keep the column unchanged
              )
              .sort((a, b) => a.colOrder - b.colOrder)
          }
        : row // Otherwise, keep the row unchanged
  );
};

const updateColWidth = (state: IRow[], action: any) => {
  return state.map(
    (row) =>
      // If the current row ID matches the target row ID
      row.uniqueId === action.uniqueId
        ? {
            ...row, // Copy the existing row properties
            row: row.row
              .map((col) =>
                // If the current column ID matches the target column ID
                col.colId === action.colId
                  ? {
                      ...col, // Copy the existing column properties
                      colWidth: action.width
                    }
                  : {
                      ...col,
                      colWidth: generateColWidth(action.width)
                    }
              )
              .sort((a, b) => a.colOrder - b.colOrder)
          }
        : row // Otherwise, keep the row unchanged
  );
};

const updateColData = (state: IRow[], action: any) => {
  return state.map(
    (row) =>
      // If the current row ID matches the target row ID
      row.uniqueId === action.uniqueId
        ? {
            ...row, // Copy the existing row properties
            row: row.row.map(
              (col) =>
                // If the current column ID matches the target column ID
                col.colId === action.colId
                  ? {
                      ...col, // Copy the existing column properties
                      colActive: action.colActive,
                      colData: action.colData // Update the column data with the provided updated data
                    }
                  : col // Otherwise, keep the column unchanged
            )
          }
        : row // Otherwise, keep the row unchanged
  );
};

const removeCol = (state: IRow[], action: any) => {
  return state.map((row) => {
    if (row.uniqueId === action.uniqueId) {
      const updatedRow = {
        ...row,
        row: row.row
          .map((col) => ({ ...col, colWidth: '12' }))
          .filter((col) => col.colId !== action.colId)
      };
      return updatedRow;
    }
    return row;
  });
};

const reducer = (state: IRow[], action: any) => {
  switch (action.type) {
    case 'GET-ALL':
      return getAll(action);
    case 'DELETE-ALL':
      return [];
    case 'ADD-ROW':
      return addRow(state, action);
    case 'REMOVE-ROW':
      return removeRow(state, action);
    case 'ROW-BG-COLOR':
      return rowBgColor(state, action);
    case 'UPDATE-ROW-ORDER':
      return updateRowOrder(state, action);
    case 'UPDATE-ROW-COL-TYPE':
      return updateRowColType(state, action);
    case 'ADD-COL':
      return addCol(state, action);
    case 'UPDATE-COL-ORDER':
      return updateColOrder(state, action);
    case 'UPDATE-COL-WIDTH':
      return updateColWidth(state, action);
    case 'UPDATE-COL-DATA':
      return updateColData(state, action);
    case 'REMOVE-COL':
      return removeCol(state, action);
    default:
      return state;
  }
};

export default reducer;
