import axios, { AxiosResponse } from 'axios';

const baseUrl = process.env.GATSBY_API_URL;

function apiHeader(multipart?: boolean): Record<string, string> {
  const token: string | null =
    typeof localStorage !== 'undefined' &&
    JSON.parse(localStorage.getItem('token') as string);
  return {
    'Accept': 'application/json',
    'Authorization': `${token ? 'Bearer ' + token : null}`,
    'Content-Type': `${multipart ? 'multipart/form-data' : 'application/json'}`
  };
}

class ApiService {
  async getRequest(api: string, params?: any): Promise<any> {
    try {
      const response: AxiosResponse = await axios.get(baseUrl + api, {
        headers: apiHeader(),
        params: {
          ...params
        }
      });

      return response.data;
    } catch (error) {
      return error;
    }
  }

  async putRequest(api: string, data: any): Promise<any> {
    try {
      const response: AxiosResponse = await axios.put(baseUrl + api, data, {
        headers: apiHeader()
      });

      return response;
    } catch (error) {
      return error;
    }
  }

  async postRequest(api: string, data: any, multipart?: boolean): Promise<any> {
    try {
      const response: AxiosResponse = await axios.post(baseUrl + api, data, {
        headers: apiHeader(multipart)
      });

      return response;
    } catch (error) {
      return error;
    }
  }

  async deleteRequest(api: string, data: any): Promise<any> {
    try {
      const response: AxiosResponse = await axios.delete(baseUrl + api, {
        data,
        headers: apiHeader()
      });

      return response;
    } catch (error) {
      return error;
    }
  }

  async patchRequest(
    api: string,
    data: any,
    multipart?: boolean
  ): Promise<any> {
    try {
      const response: AxiosResponse = await axios.patch(baseUrl + api, data, {
        headers: apiHeader(multipart)
      });

      return response;
    } catch (error) {
      return error;
    }
  }
}

export default new ApiService();
