import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import authReducer from './reducers/auth';
import contactReducer from './reducers/contact';
import dynamicContentReducer from './reducers/dynamicContent';
import messageReducer from './reducers/message';
import reviewsReducer from './reducers/reviews';
import settingsReducer from './reducers/settings';
import subscribeReducer from './reducers/subscribe';
import usersReducer from './reducers/users';

const rootReducer = combineReducers({
  auth: authReducer,
  contact: contactReducer,
  dynamic: dynamicContentReducer,
  message: messageReducer,
  reviews: reviewsReducer,
  settings: settingsReducer,
  subscribe: subscribeReducer,
  users: usersReducer
});

const store = configureStore({
  devTools: true,
  reducer: rootReducer
  /* middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }), */
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type Thunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
